import Logout from "../auth/logout";

export default function Navbar(props){
    // require("../../assets/css/light-bootstrap-dashboard.css");
    return (
        <nav className="navbar navbar-expand-lg " color-on-scroll="500">
            <div className="container-fluid">
                <a className="navbar-brand" href="#p"> {props.page_title} </a>
                <button href="" className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-bar burger-lines"></span>
                    <span className="navbar-toggler-bar burger-lines"></span>
                    <span className="navbar-toggler-bar burger-lines"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-end" id="navigation">
                    {/* <ul className="nav navbar-nav mr-auto">
                        <li className="nav-item">
                            <a href="#" className="nav-link" data-toggle="dropdown">
                                <i className="nc-icon nc-palette"></i>
                                <span className="d-lg-none">Dashboard</span>
                            </a>
                        </li>
                        <li className="dropdown nav-item">
                            <a href="#" className="dropdown-toggle nav-link" data-toggle="dropdown">
                                <i className="nc-icon nc-planet"></i>
                                <span className="notification">5</span>
                                <span className="d-lg-none">Notification</span>
                            </a>
                            <ul className="dropdown-menu">
                                <a className="dropdown-item" href="#">Notification 1</a>
                                <a className="dropdown-item" href="#">Notification 2</a>
                                <a className="dropdown-item" href="#">Notification 3</a>
                                <a className="dropdown-item" href="#">Notification 4</a>
                                <a className="dropdown-item" href="#">Another notification</a>
                            </ul>
                        </li>
                        <li className="nav-item">
                            <a href="#" className="nav-link">
                                <i className="nc-icon nc-zoom-split"></i>
                                <span className="d-lg-block">&nbsp;Search</span>
                            </a>
                        </li>
                    </ul> */}
                    <ul className="navbar-nav ml-auto">
                        {/* <li className="nav-item">
                            <a className="nav-link btn btn-primary" href="#pablo">
                                <span className="no-icon">Account</span>
                            </a>
                        </li> */}
                        {/* <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="http://example.com" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span className="no-icon">Account</span>
                            </a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                <a className="dropdown-item" href="#">Action</a>
                                <a className="dropdown-item" href="#">Another action</a>
                                <a className="dropdown-item" href="#">Something</a>
                                <a className="dropdown-item" href="#">Something else here</a>
                                <div className="divider"></div>
                                <a className="dropdown-item" href="#">Separated link</a>
                            </div>
                        </li> */}
                        <li className="nav-item">
                            <Logout classes="nav-link" redirectUrl="/admin/login"></Logout>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}