import { config } from "../../appconfig";

export default function Footer(){
    return (
        <footer className="footer">
            <div className="container-fluid">
                <nav>
                    
                </nav>
            </div>
        </footer>
    )
}