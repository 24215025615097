import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Auth from "../../helpers/auth";
import $ from "jquery";
import httpClient from "../../httpClient";
import Cropper from 'react-easy-crop'
import getCroppedImg from "./CropImage"
import bmbg from "../../assets/img/bmbg.png"
import Logout from "../auth/logout";

import { config } from "../../appconfig";
import { formatMoney, loadScripts } from "../../utils";
import { toast, ToastContainer } from "react-toastify";
var Loader = require('react-loader');
const states_and_lgas = require("../../helpers/statesandlgas.json");
const plans = [
    { id: 1, name: "Gold", price: 2000, description: "A lengthy description" },
    { id: 2, name: "Platinum", price: 25000, description: "A lengthy description for this too" }
]

const user = Auth.user();

const uploadInputStyle = {
    position: "absolute",
    visibility: "hidden",
    zIndex: -1,
    top: "6px",
    left: 0,
    fontSize: "15px",
    color: "rgb(153, 153, 153)"
}

export default class UserDashboard extends Component{
    constructor(props){
        super(props);
        this.fileInput = React.createRef();

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleFileInputChange = this.handleFileInputChange.bind(this);
        this.handleStateInputChange = this.handleStateInputChange.bind(this);
        this.uploadProfilePicture = this.uploadProfilePicture.bind(this);
    }
    state = {
        phoneNumber: user?.phoneNumber,
        firstname: user?.firstname,
        lastname: user?.lastname,
        username: user?.username,
        date_of_birth: user?.dob ? new Date(user?.dob).toISOString().split('T')[0] : "",
        gender: user?.gender,
        school_name: user?.school,
        address: user?.address?.street,
        state: user?.address?.state,
        lga: user?.address?.lga,
        validationErrors: [],
        loading: false,
        image: user?.avatar_url == null ? require("../../assets/img/default-member-avatar.png").default : user?.avatar_url,
        crop: { x: 0, y: 0 },
        zoom: 1,
        aspect: 4 / 4,
        uploadFile: null,
        loading: false,
        plans: [],
        chosenPlan: null,
        activeSubscription: null
    }
    componentDidMount(){
        httpClient.get('plans/active/').then(res => {
            const plans = res.data.data;
            this.setState({plans})
        }).catch(err => {
            console.log("There was an error fetching plans", err);
        })

        httpClient.get("subscriptions/get-subscription").then(res => {
            let sub = res.data.data;
            this.setState({activeSubscription: sub});
        }).catch(err => {
            console.log(err);
        })

        loadScripts(["https://js.paystack.co/v1/inline.js"])
    }
    onCropChange = (crop) => {
        this.setState({ crop })
    }

    onCropComplete = async (croppedArea, croppedAreaPixels) => {
        console.log(croppedArea, croppedAreaPixels)
        
        try {
            const croppedImage = await getCroppedImg(
                this.state.image,
                croppedAreaPixels,
                0
            )    
            console.log(croppedImage);
            this.setState({uploadFile: croppedImage});
        } catch (error) {
            console.log(error);
        }
        
    }

    onZoomChange = (zoom) => {
        this.setState({ zoom })
    }

    doPaystackPayment = (chosenPlan) => {
        
        this.setState({chosenPlan});
        let handler = window.PaystackPop.setup({
            key: config.paystack_public_key, // Replace with your public key
            email: this.state.username + "@brightminds.com",
            amount: Number(chosenPlan.price) * 100,
            ref: '' + Math.floor((Math.random() * 1000000000) + 1), // generates a pseudo-unique reference. Please replace with a reference you generated. Or remove the line entirely so our API will generate one for you
            // label: "Optional string that replaces customer email"
            onClose: () => {
                alert('You have closed the payment window.');
            },
            callback: (response) => {
                // let message = 'Payment complete! Reference: ' + response.reference;
                this.setState({ loading: true })
                let paymentData = {
                    amount: chosenPlan.price + "",
                    transaction_ref: response.reference,
                    planId: chosenPlan.id
                }
                httpClient.post("/subscriptions/subscribe", paymentData).then(res => {
                    // console.log(res.data);
                    toast.success("Your subscription has been confirmed");
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                }).catch(err => {
                    toast.error("There was a problem verifying your payment");
                }).finally(res => {
                    this.setState({ loading: false })
                })
                
            }
        });
        handler.openIframe();
    }
    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }
    handleStateInputChange(event) {
        const target = event.target;
        const value = target.value;
        this.setState({ state: value });
        
        const selectedState = this.state.states_list.find(item => {
            return item.state.name == value;
        });

        if (selectedState != undefined) {
            this.setState({ lgas_list: selectedState.state.locals });
        } else {
            this.setState({ lgas_list: [] });
        }

    }
    handleFileInputChange(event){
        const input = event.target;
        
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            $('#profilePicPreviewModal').modal('show')
            reader.onload = (e) => {
                
                this.setState({
                    image: e.target.result
                })
                
                $('.profile-pic-preview')
                    .attr('src', e.target.result)
            };


            reader.readAsDataURL(input.files[0]);
            

        }
    }
    uploadProfilePicture(){
        const fileToUpload = this.fileInput.current.files[0];
        if (fileToUpload != null){
            this.setState({loading: true})
            var formData = new FormData();
            formData.append('avatar', fileToUpload)
            httpClient.post("users/upload-avatar", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data;',
                }
            }).then(res => {
                console.log("upload was successful", res.data);
                console.log("uploaded url is", res.data.data.avatar_url);
                Auth.updateUser({avatar_url: res.data.data.avatar_url});
                $('#profilePicPreviewModal').modal('hide')
            }).catch(err => {
                console.log("An error occured", err);
            }).finally(() => {
                this.setState({loading: false})
                // this.setState({
                //     image: Auth.user().avatar_url
                // })
            })
        }
    }
    render(){
        if (!Auth.isLoggedIn() || !Auth.userIsOfType("member")){
            Auth.logout();
            return <Redirect to="/login" />
        }
        require("../../assets/css/member-dashboard.css");
        return(
            <div className="bodyWrapper">
                <Logout classes="btn btn-danger float-right" />
                <div className="text-center mb-3">
                    <img src={process.env.PUBLIC_URL + '/logo.png'} className="bodyLogo" />
                </div>
                <div className="">
                    <div className="row">
                        <div className="col-md-2"></div>
                        <div className="col col-md-8">
                            <div className="profile-header-area p-4 rounded" style={{ background: `url(${bmbg})`, backgroundSize: "cover" }}>
                                
                                <div className="d-flex align-items-center">
                                    <div className="">
                                        <div className="dropdown">
                                            <a href="#" data-toggle="dropdown" aria-haspopup="true">
                                                <img src={this.state.image} className="rounded" style={{ width: 80, height: 80 }} />
                                            </a>
                                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                <a className="dropdown-item" href="#">
                                                    <label className="avatarUploadButton" htmlFor="upload"> Upload Photo
                                                    <input id="upload" type="file" ref={this.fileInput} style={uploadInputStyle} onChange={this.handleFileInputChange} accept="image/*" />
                                                    </label>
                                                </a>
                                                <Logout classes="dropdown-item" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ml-4">
                                        <span className="text-white" style={{ fontSize: "1spanpx" }}>{Auth.user().firstname} {Auth.user().lastname}</span>
                                        <br/>
                                        <small className="text-white"><i className="fa fa-phone-alt"></i> {Auth.user().phoneNumber}</small>
                                        <br/>
                                        {(this.state.activeSubscription != null && this.state.activeSubscription.plan != undefined) ? <small className="text-white"><i className="fa fa-box-open"></i> {this.state.activeSubscription.plan.plan_name} member</small>: ''}
                                    </div>
                                </div>
                                
                            </div>
                            <div className="profile-body-area">
                                <ul className="nav nav-tabs nav-fill" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Membership</a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Profile</a>
                                    </li>
                                </ul>
                                <div className="tab-content planTabs p-3" id="myTabContent">
                                    <div className="tab-pane fade show active pt-3" id="home" role="tabpanel" aria-labelledby="home-tab">
                                        {this.state.activeSubscription == null ? <div className="">
                                            {this.state.plans.map((plan) => {
                                                return (
                                                    <div className="card planCard mt-2" key={plan.id}>
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-12 col-sm-8">
                                                                    <h4>{plan.plan_name}</h4>
                                                                    <p>{plan.description}</p>
                                                                </div>
                                                                <div className="col-12 col-sm-4">
                                                                    <p style={{ fontSize: 18 }} className="planPrice">{formatMoney(plan.price) }</p>
                                                                    <button className="rounded-pill buyPlanButton" onClick={(e) => this.doPaystackPayment(plan, e)}>Choose Plan</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                            
                                        </div> : <div className="" style={{ fontFamily: "Roboto" }}>
                                            Hurray!!! You are a {this.state.activeSubscription.plan.plan_name} member
                                        </div>}
                                    </div>
                                    <div className="tab-pane fade pt-3" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                        <form>
                                            <div className="form-group">
                                                <input className="form-control" type="text" placeholder="First name" name="firstname" value={this.state.firstname} onChange={this.handleInputChange}/>
                                            </div>
                                            <div className="form-group">
                                                <input className="form-control" type="text" placeholder="Last name" value={this.state.lastname} onChange={this.handleInputChange} />
                                            </div>
                                            <div className="form-group">
                                                <input className="form-control" type="text" placeholder="Username" value={this.state.username} onChange={this.handleInputChange} />
                                            </div>
                                            <div className="form-group">
                                                <input className="form-control" type="tel" placeholder="Phone number" value={this.state.phoneNumber} onChange={this.handleInputChange} />
                                            </div>
                                            <div className="form-group">
                                                <input className="form-control" type="date" placeholder="Date of birth" value={this.state.date_of_birth} onChange={this.handleInputChange}/>
                                            </div>
                                            <div className="form-group">
                                                <select className="form-control" name="gender" value={this.state.gender} onChange={this.handleInputChange}>
                                                    <option value="">Gender</option>
                                                    <option value="female">Female</option>
                                                    <option value="male">Male</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <input className="form-control" placeholder="Name of school" name="school_name" value={this.state.school_name} onChange={this.handleInputChange}/>
                                            </div>
                                            <div className="form-group">
                                                <input className="form-control" placeholder="Your home address" name="address" value={this.state.address} onChange={this.handleInputChange} />
                                            </div>
                                            <div className="form-group">
                                                <select className="form-control" value={this.state.state} name="state" onChange={this.handleStateInputChange}>
                                                    <option value="">Select state</option>
                                                    {states_and_lgas.map((item) => {
                                                        return <option value={item.state.name} key={item.state.id}>{item.state.name}</option>
                                                    })}
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <input className="form-control" value={this.state.lga} onChange={this.handleInputChange}/>
                                                {/* <select className="form-control" name="lga" onChange={this.handleInputChange}>

                                                    {this.state.lgas_list.map((lga) => {
                                                        return <option>{lga.name}</option>
                                                    })}
                                                </select> */}
                                            </div>
                                            <div className="form-group"></div>
                                            <div className="text-center mb-4">
                                                {/* <button className="btn btn-primary" type="submit">Update Profile</button> */}
                                            </div>
                                        </form>
                                    </div>
                                    <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">...</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="profilePicPreviewModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Upload Picture</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                
                                {/* <div className="image-preview-area" style={{ height:300, width: 300, bottom: 80 }}>
                                    <Cropper
                                        image={this.state.image}
                                        crop={this.state.crop}
                                        zoom={this.state.zoom}
                                        aspect={this.state.aspect}
                                        onCropChange={this.onCropChange}
                                        onCropComplete={this.onCropComplete}
                                        onZoomChange={this.onZoomChange}
                                    />
                                </div> */}
                                
                                <img src="" className="profile-pic-preview rounded-circle" width="120" height="120"/>
                            </div>
                            <div className="modal-footer">
                                {/* <div className="controls">
                                    <div className="form-group">
                                        <label htmlFor="formControlRange">Zoom image</label>
                                        <input type="range" name="zoom" value={this.state.zoom} min={1} max={3} step={0.1} aria-labelledby="Zoom" className="form-control-range" onChange={this.handleInputChange} />
                                    </div>
                                </div> */}
                                <Loader loaded={!this.state.loading}>
                                    {/* <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button> */}
                                    <button type="button" className="btn btn-success ml-auto" onClick={this.uploadProfilePicture}>Upload</button>
                                </Loader>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}