import { Component } from "react";
import AdminLayout from "../../layouts/admin";
import httpClient from "../../../httpClient";
import { toast } from "react-toastify";
import Auth from "../../../helpers/auth";
import { Redirect } from "react-router-dom";
import { trim } from "jquery";
import ReactLoader from "react-loader";
var dayjs = require('dayjs');
export default class CreateCompetition extends Component {
    state = {
        name: "",
        description: "",
        membership_required: [],
        banner: null,
        opening_date: "",
        closing_date: "",
        uploaded_competition: null,
        validationErrors: [],
        plans: [],
        loading: false
    }
    componentDidMount() {
        this.fetchPlans();
    }
    fetchPlans = () => {
        httpClient.get('plans/active').then(res => {
            const plans = res.data.data;
            this.setState({ plans })
        }).catch(err => {
            console.log("There was an error fetching plans", err);
        })
    }

    handleInputChange = (e) => {
        const target = e.target;
        let name = target.name;
        let value = null;

        if (target.type === 'checkbox') {
            const options = [...this.state.membership_required]
            let index;
            if (target.checked) {
                options.push(target.value)
            } else {
                index = options.indexOf(target.value)
                options.splice(index, 1)
            }
            this.setState({ membership_required: options });
            return;
        } else if (target.type === 'file') {
            value = target.files[0];
        } else if (target.multiple === true) {
            let options = Array.from(e.target.selectedOptions, option => option.value);
            value = options.filter(function (el) {
                return el != null && el != "" && el != undefined;
            });
        }
        else {
            value = target.value;
        }
        this.setState({
            [name]: value
        });
    }

    submitForm = (e) => {
        e.preventDefault();
        this.setState({ loading: true, validationErrors: [] });
        let formData = {
            title: this.state.name,
            description: this.state.description,
            opening_date: this.state.opening_date,
            closing_date: this.state.closing_date,
            membership_types: this.state.membership_required
        }
        httpClient.post("competitions", formData).then(res => {
            this.setState({ uploaded_competition: res.data.data });
            if (this.state.banner != null) {
                this.uploadBanner();
            } else {
                toast.success("Competition created successfully, eligible members will now be able to participate");
                const { history } = this.props;
                history.push("/admin/competitions");
            }
        }).catch(err => {
            if (err.response && err.response.status == 400) {

                let formErrors = err.response.data.message;
                if (Array.isArray(formErrors) || (typeof formErrors === 'object' && formErrors !== null)) {
                    formErrors = Object.values(formErrors).flat();
                    this.setState({ validationErrors: formErrors })
                } else {
                    formErrors = formErrors.split(",");
                    formErrors = formErrors.map(item => trim(item));
                    this.setState({ validationErrors: formErrors })
                }
                toast.error("Please fill all required fields");
            } else {
                if (err.response && err.response.data.message != undefined) {
                    const errorMessage = [err.response.data.message];
                    this.setState({ validationErrors: errorMessage });
                }
            }
            toast.error("An error occured, please try again");
            this.setState({ loading: false });
        })
    }

    uploadBanner = () => {
        let formData = new FormData();
        formData.append('banner', this.state.banner);
        httpClient.post(`competitions/upload-image/${this.state.uploaded_competition.id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data;',
            }
        }).then(res => {
            this.setState({ loading: false });
            toast.success("Competition created successfully, members will now be able to participate");
            const { history } = this.props;
            history.push("/admin/competitions");
        }).catch(err => {
            this.setState({ loading: false });
            toast.error("There was an error uploading the competition banner")
        })
    }

    render = () => {
        return (
            <div>
                <AdminLayout page_title="Create a new competition">
                    <div className="">
                        <div className="row">
                            <div className="col-md-2"></div>
                            <div className="col col-md-8">
                                <form onSubmit={this.submitForm}>
                                    {this.state.validationErrors.length > 0 &&
                                        <ul className="list-group">
                                            {this.state.validationErrors.map((error) => {
                                                return (<li className="list-group-item list-group-item-danger" key={error}>{error.charAt(0).toUpperCase() + error.slice(1)}</li>)
                                            })}
                                        </ul>
                                    }
                                    <div className="form-group">
                                        <label htmlFor="Competition Name">Competition name</label>
                                        <input type="text" className="form-control" placeholder="Name of competition" name="name" onChange={this.handleInputChange} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="Competition description">Competition description</label>
                                        <textarea className="form-control" name="description" placeholder="Description of competition" onChange={this.handleInputChange}></textarea>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="Date">Opening date</label>
                                        <input className="form-control" type="datetime-local" min={dayjs(new Date().toDateString()).format("YYYY-MM-DDTHH:mm")} name="opening_date" onChange={this.handleInputChange} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="Date">Closing date</label>
                                        <input className="form-control" type="datetime-local" min={dayjs(this.state.opening_date).format("YYYY-MM-DDTHH:mm")} name="closing_date" onChange={this.handleInputChange} />
                                    </div>
                                    {/* <div className="form-group">
                                        <label htmlFor="Membership required">Select which members can participate  <small>(Leave blank to allow all member types)</small></label>
                                        {this.state.plans.map((plan) => {
                                            return (
                                                <div key={plan.id}>
                                                    <label htmlFor={plan.id}>
                                                        <input type="checkbox" name="membership_required" value={plan.id} id={plan.id} onChange={this.handleInputChange} />
                                                        <span> {plan.plan_name}</span>
                                                    </label>
                                                </div>
                                            )

                                        })}
                                    </div> */}
                                    <div className="form-group">
                                        <label htmlFor="Banner">Upload competition banner</label>
                                        <input type="file" className="form-control" name="banner" accept="image/*" onChange={this.handleInputChange}></input>
                                    </div>
                                    <div>
                                        <ReactLoader loaded={!this.state.loading}>
                                            <button className="btn btn-primary" type="submit">Create competition</button>
                                        </ReactLoader>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </AdminLayout>
            </div>
        )
    }
}