import { type } from "jquery";

export function loadScripts(url){
    let urls = [];
    if (typeof url == "string"){
        urls.push(url);
    }else{
        urls = url.slice();
    }
    urls.forEach(urlString => {
        const script = document.createElement("script");
        script.src = urlString;
        // script.async = true;
        // script.defer = true;
        document.body.appendChild(script);
    });
}

export function normalizePhoneNumber(phone){
    let phoneNumber = phone;
    phoneNumber = phone.replace(/[^0-9]/g, '');
    if (phoneNumber.startsWith("234")){
        phoneNumber = phoneNumber.replace("234", 0);
    }
    return phoneNumber;
    
}

export function internationalizePhoneNumber(phone){
    if (phone == null || phone == ""){
        return "";
    }
    let phoneNumber = normalizePhoneNumber(phone);
    return phoneNumber.replace("0", "+234");
}

export function formatMoney(figure) {
    if (isNaN(figure)) {
        return "0.0";
    }
    return Number(figure).toLocaleString('en', { style: 'currency', currency: 'NGN' })
}

export function stripTags(text) {
    const regex = /(<([^>]+)>)/ig;
    const result = text.replace(regex, '');
    return result;
}