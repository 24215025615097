import axios from "axios";
import { config } from "./appconfig";
import Auth from "./helpers/auth";

const httpClient = axios.create({
    baseURL: config.api_base_url,
    headers: { Authorization: "Bearer " + Auth.getAuthToken() }
});

export default httpClient;
