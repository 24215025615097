import React from "react";
import ReactDOM from "react-dom";
import AdminLayout from "./../layouts/admin";
import "../../assets/css/css-import-util.css";
import httpClient from "../../httpClient";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";
import Auth from "../../helpers/auth";
import { internationalizePhoneNumber } from "../../utils";
var Loader = require('react-loader');
class AdminLogin extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            identifier: "",
            password: "",
            validationErrors: [],
            loading: false,
            userType: this.props.location.pathname == "/admin/login" ? 'admin' : 'member'
        }
        this.doLogin = this.doLogin.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount(){
        
    }
    render(){
        if (Auth.isLoggedIn() && Auth.isAdmin()) {
            return <Redirect to="/admin/dashboard" />
        }
        require("../../assets/css/login.css")
        return (
            <div className="adminLogin">
                <div className="limiter">
                    <div className="container-login100">
                        <div className="wrap-login100 p-l-55 p-r-55 p-t-45 p-b-50">
                            <form className="login100-form validate-form" onSubmit={this.doLogin}>
                                <div className="text-center">
                                    <img src={process.env.PUBLIC_URL + '/logo.png'} width="70"/>
                                </div>
                                <span className="login100-form-title p-b-33">
                                    {this.state.userType == 'admin' ? "Administrator" : "Member"} Account Login
                                </span>
                                {this.state.validationErrors.length > 0 && 
                                    <ul className="list-group">
                                    {this.state.validationErrors.map((error) => {
                                        return (<li className="list-group-item list-group-item-danger">{error}</li>)
                                    })}
                                    </ul>

                                }
                                <div className="wrap-input100 validate-input" data-validate="Valid email is required: ex@abc.xyz">
                                    <input className="input100" type="email" name="identifier" placeholder="Username" value={this.state.identifier} onChange={this.handleInputChange}/>
                                        <span className="focus-input100-1"></span>
                                        <span className="focus-input100-2"></span>
                                </div>
                                <div className="wrap-input100 rs1 validate-input" data-validate="Password is required">
                                    <input className="input100" type="password" name="password" placeholder="Password" value={this.state.password} onChange={this.handleInputChange}/>
                                        <span className="focus-input100-1"></span>
                                        <span className="focus-input100-2"></span>
                                </div>
                                <Loader loaded={!this.state.loading}>
                                <div className="container-login100-form-btn m-t-20">
                                    
                                    <button className="login100-form-btn" type="submit">
                                        Sign in
                                    </button>
                                    
                                </div>
                                </Loader>
                            </form>
                        </div>
                    </div>
                </div>
                    
            </div>
        );
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    doLogin(e){
        this.setState({validationErrors: [], loading: true})
        e.preventDefault();
        
        const loginData = {
            // phoneNumber: internationalizePhoneNumber(this.state.identifier),
            email: this.state.identifier,
            password: this.state.password
        }
        
        const authUrl = "auth/admin/login";
        httpClient.post(authUrl, loginData).then(res => {
            console.log(res.data.data);
            const resData = res.data.data;
            const token = resData.token;
            const user = resData.user;
            Auth.saveToken(token);
            Auth.saveUser(user);
            const { history } = this.props;
            window.location.href="/admin/dashboard";
        }).catch(err => {
            
            if (err.response && err.response.status == 400){

                let formErrors = err.response.data.message;
                if (Array.isArray(formErrors) || (typeof formErrors === 'object' && formErrors !== null)){
                    formErrors = Object.values(formErrors).flat();
                    this.setState({validationErrors: formErrors})
                }else{
                    formErrors = formErrors.split(",");
                    this.setState({ validationErrors: formErrors })
                }
            }else{
                if(err.response && err.response.data.message != undefined){
                    const errorMessage = [err.response.data.message];
                    this.setState({ validationErrors: errorMessage});
                }
            }
        }).finally(response => {
            this.setState({loading: false});
        })
    }
}

export default AdminLogin;

