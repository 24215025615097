import React, { Component } from "react";
import AdminLayout from "../../layouts/admin";
import { loadScripts } from "../../../utils";
// import 'jquery'
// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

import FroalaEditorComponent from 'react-froala-wysiwyg';
import httpClient from "../../../httpClient";
import { toast } from "react-toastify";
import ReactLoader from "react-loader";

export default class CreateAnnouncement extends Component{
    state = {
        title: "",
        content: "",
        validationErrors: [],
        loading: false
    }

    constructor(props){
        super(props);
    }

    componentDidMount(){
        
    }
    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    updateTextContent = (content) => {
        
        this.setState({content: content});
    }

    submit = (event) => {
        event.preventDefault();
        let postData = {
            title: this.state.title,
            content: this.state.content
        }
        this.setState({loading: true, validationErrors: []})
        httpClient.post("/announcements", postData).then(res => {
            toast.success("Announcement published successfully");
            this.setState({ loading: false })
            const { history } = this.props;
            history.push("/admin/announcements");
        }).catch(err => {
            if (err.response && err.response.status == 400) {

                let formErrors = err.response.data.message;
                if (Array.isArray(formErrors) || (typeof formErrors === 'object' && formErrors !== null)) {
                    formErrors = Object.values(formErrors).flat();
                    this.setState({ validationErrors: formErrors })
                } else {
                    formErrors = formErrors.split(",");
                    formErrors = formErrors.map(item => item.trim());
                    this.setState({ validationErrors: formErrors })
                }
                toast.error("Please fill all required fields");
            } else {
                if (err.response && err.response.data.message != undefined) {
                    const errorMessage = [err.response.data.message];
                    this.setState({ validationErrors: errorMessage });
                }
                toast.error("An error occured, please try again");
                this.setState({ loading: false })
            }
            
            
        }).finally(() => {
            
        })
    }

    render() {

        return (
            <div>
                <AdminLayout page_title="Publish announcement">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col col-md-1 d-none d-md-block"></div>
                            <div className="col col-md-10">
                                <div className="card card-body">
                                    {this.state.validationErrors.length > 0 &&
                                        <ul className="list-group">
                                            {this.state.validationErrors.map((error) => {
                                                return (<li className="list-group-item list-group-item-danger" key={error}>{error.charAt(0).toUpperCase() + error.slice(1)}</li>)
                                            })}
                                        </ul>

                                    }
                                    <form onSubmit={this.submit}>
                                        <div className="form-group">
                                            <input className="form-control" type="text" placeholder="Title" name="title" value={this.state.title} onChange={this.handleInputChange} />
                                        </div>
                                        <FroalaEditorComponent tag='textarea' model={this.state.content} onModelChange={this.updateTextContent} config={{ placeholderText: "Type or paste content here" }} />
                                        <div className="form-group"></div>
                                        <div className="text-center mb-4">
                                            
                                            <ReactLoader loaded={!this.state.loading}>
                                                <button className="btn btn-primary" type="submit">Publish</button>
                                            </ReactLoader>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </AdminLayout>
            </div>
        )
    }
}