import { Component } from "react";
import { Redirect } from "react-router-dom";
import Auth from "../../helpers/auth";

export default class Logout extends Component{
    constructor(props){
        super(props);
    }
    state = {
        navigate: false
    }

    logout = (e) => {
        e.preventDefault();
        Auth.logout();
        this.setState({navigate: true});
    }

    render(){
        const {navigate} = this.state;
        const redirectTo = this.props.redirectUrl ?? "/login";
        if (navigate){
            return <Redirect to={redirectTo} push={true} />
        }

        return <a href="#" className={this.props.classes} onClick={this.logout}>{this.props.children ?? "Logout"}</a>
    }
}