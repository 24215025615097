import { Component } from "react";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import Auth from "../../../helpers/auth";
import httpClient from "../../../httpClient";
import AdminLayout from "../../layouts/admin";

export default class AdminDashboard extends Component{
    state = {
        dashboard: null
    }
    getDashboard = () => {
        httpClient.get("admin/dashboard").then(res => {
            this.setState({dashboard: res.data.data})
        }).catch(err => {
            // toast.error("There was an error getting dashboard data, please refresh page");
        })
    }
    componentDidMount(){
        this.getDashboard();
    }
    render(){
        if (!Auth.isLoggedIn() || !Auth.isAdmin()){
            Auth.logout();
            return <Redirect to="/admin/login"></Redirect>
        }
        
        return (
            <div>
                <AdminLayout page_title="Dashboard">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col">
                                <div className="card card-body card-success">
                                    <p className="font-weight-bold">Members</p>
                                    <p>{this.state.dashboard?.members ?? 0}</p>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card card-body">
                                    <p className="font-weight-bold">Events</p>
                                    <p>{this.state.dashboard?.events ?? 0}</p>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card card-body">
                                    <p className="font-weight-bold">Competitions</p>
                                    <p>{this.state.dashboard?.competitions ?? 0}</p>
                                </div>
                            </div>
                            {/* <div className="col">
                                <div className="card card-body">
                                    <p className="font-weight-bold">Subscriptions</p>
                                    <p>300</p>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </AdminLayout>
            </div>
        )
    }
}