import { Component } from "react";
import { Link } from "react-router-dom";
import httpClient from "../../../httpClient";
import AdminLayout from "../../layouts/admin";
import $ from "jquery";
import Pagination from "react-pagination-js";
import "react-pagination-js/dist/styles.css";
import { stripTags } from "../../../utils";
import { toast } from "react-toastify";

export default class ListAnnouncements extends Component{
    constructor(props) {
        super(props);
    }

    state = {
        announcements: [],
        pagination: null,
        currentAnnouncement: null,
        loading: false,
    }

    componentDidMount(){
        this.getAnnouncements();
    }
    getAnnouncements = async (currentPage=1) => {
        try {
            this.setState({ currentPage });
            let getData = {
                page: currentPage
            }
            let response = await httpClient.get('announcements', { params: getData });
            let announcements = response.data.data.data;
            this.setState({ announcements });
            this.setState({ pagination: response.data.data })

        } catch (error) {
            if (error.response != undefined && error.response.data.message != null) {
                this.setState({ error: error.response.data.message })
            } else {
                this.setState({ error: "There was an error fetching announcements" });
            }
        }
    }

    openViewModal = (index, e) => {
        e.preventDefault();
        let currentAnnouncement = this.state.announcements[index];
        this.setState({ currentAnnouncement });
        
        $("#viewModal").modal("show");
    }

    deleteAnnouncement = (index, e) => {
        e.preventDefault();
        if (!window.confirm("Are you sure you want to delete this announcement? This action is irreversible")) {
            return;
        }
        let announcements = this.state.announcements;

        let a = this.state.announcements[index];
        httpClient.delete(`announcements/${a.id}`).then(res => {
            toast.info("Announcement deleted successfully");
            announcements.splice(index, 1);
            this.setState({ announcements })
        }).catch(err => {
            toast.error("There was an error deleting that announcement, please try again");
        })
    }

    previewText = (text) => {
        let result = stripTags(text);
        return result.substring(0, 120) + (result.length > 120 ? "..." : "");
    }

    render() {
        if (this.state.announcements.length < 1) {
            return (
                <AdminLayout page_title={`Announcements (${this.state.pagination?.total ?? 0})`}>
                    <div>
                        <Link to="/admin/announcements/create" className="btn btn-primary float-right">Add event</Link>
                    </div>
                    <div className="text-center">
                        No announcements have been created yet. <br />
                        <Link to="/admin/announcements/create">Create one</Link>
                    </div>
                </AdminLayout>
            )
        }
        if (this.state.error != null) {
            return (
                <AdminLayout page_title={`Announcements (${this.state.pagination?.total ?? 0})`}>
                    <div>
                        <Link to="/admin/announcements/create" className="btn btn-primary float-right">Add event</Link>
                    </div>
                    <div className="text-center">
                        {this.state.error}<br />
                        <button className="btn btn-warning" onClick={() => { window.location.reload(true) }}>Reload page</button>
                    </div>
                </AdminLayout>
            )
        }
        return (
            <div>
                <AdminLayout page_title={`Announcements (${this.state.pagination?.total ?? 0})`}>
                    <div>
                        <Link to="/admin/announcements/create" className="btn btn-primary float-right">Add new</Link>
                    </div>

                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead className="thead-dark">
                                <tr>
                                    <th scope="col">Title</th>
                                    <th scope="col">Content</th>
                                    <th scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.announcements.map((a, index) => {
                                    return (
                                        <tr key={a.id}>
                                            <td scope="row">{a.title}</td>
                                            {/* <td>{event.location}</td> */}
                                            <td>{this.previewText(a.content)}</td>
                                            <td>
                                                <div className="dropdown">
                                                    <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <i className="fa fa-cog"></i> Actions
                                                </button>
                                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                        <button className="dropdown-item" onClick={(e) => this.openViewModal(index, e)}><i className="fa fa-eye"></i> View</button>
                                                        {/* <Link className="dropdown-item" to={`/admin/events/${event.id}/edit`}><i className="fa fa-edit"></i> Edit</Link> */}
                                                        <a className="dropdown-item" href="#" onClick={(e) => this.deleteAnnouncement(index, e)}><i className="fa fa-times"></i> Delete</a>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}

                            </tbody>
                        </table>
                        <Pagination
                            currentPage={this.state.currentPage}
                            totalSize={this.state.pagination?.total ?? 0}
                            changeCurrentPage={this.getAnnouncements}
                            sizePerPage={this.state.pagination?.limit}
                            numberOfPagesNextToActivePage={3}
                            theme="bootstrap"></Pagination>
                    </div>
                </AdminLayout>
                <div className="modal fade" id="viewModal" tabIndex="-1" aria-labelledby="viewAnnouncement" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">{this.state.currentAnnouncement?.title}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div dangerouslySetInnerHTML={{ __html: this.state.currentAnnouncement?.content }}>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}