import { Component } from "react";
import AdminLayout from "../../layouts/admin";
import httpClient from "../../../httpClient";
import { toast } from "react-toastify";
import Auth from "../../../helpers/auth";
import { Redirect } from "react-router-dom";
import { trim } from "jquery";
import $ from "jquery";
import ReactLoader from "react-loader";
var dayjs = require('dayjs');
export default class EditEvent extends Component{
    eventId = "";
    constructor(props){
        super(props);
        this.eventId = this.props.match.params.id;
    }
    state = {
        event: null,
        title: "",
        description: "",
        // location: "",
        location_template: {
            location: "",
            start_date: "",
            end_date: "",
        },
        locations: [],
        allowed_plans: [],
        membership_types: [],
        banner: null,
        bannerFile: null,
        start_date: "",
        end_date: "",
        validationErrors: [],
        plans: [],
        loading: false
    }
    componentDidMount(){
        this.fetchEvent().then(res => {
            this.fetchPlans();
        });
        
    }
    fetchPlans = () => {
        httpClient.get('plans/active').then(res => {
            const plans = res.data.data;
            // this.setState({ plans })
            let allowed_plans = [];
            plans.forEach(plan => {
                let planObj = {}
                planObj.id = plan.id;
                planObj.plan_name = plan.plan_name;
                let find = this.state.allowed_plans.find(allowed => {
                    return allowed.id == plan.id;
                })
                
                if (find != undefined){
                    planObj.checked = true;
                }else{
                    planObj.checked = false;
                }
                allowed_plans.push(planObj);
            });
            this.setState({allowed_plans: allowed_plans});
            
        }).catch(err => {
            console.log("There was an error fetching plans", err);
        })
    }
    fetchEvent = () => {
        return httpClient.get(`events/${this.eventId}`).then(res => {
            let event = res.data.data;
            this.setState({...event, event});
            let allowed_plans = [];
            this.state.allowed_plans.forEach(plan => {
                let planObj = plan
                planObj.checked = true;
                allowed_plans.push(planObj);
            });
            let locations = [...event.location];
            this.setState({locations});
            this.setState({ allowed_plans: allowed_plans });
        })
    }

    addNewLocationField = () => {
        let locations = [...this.state.locations];
        locations.push({ ...this.state.location_template });
        this.setState({ locations });
    }

    removeLocationField = (index) => {
        
        let locations = [...this.state.locations];
        
        let removed = locations.splice(index, 1);
        
        
        this.setState({ locations: [...locations] });
    }

    handleLocationDateInputChange = (index, e) => {
        let allLocations = [...this.state.locations];
        let locationItem = allLocations[index];
        locationItem[e.target.name] = e.target.value;

        allLocations[index] = locationItem;
        this.setState({ locations: [...allLocations] });
    }

    handleInputChange = (e) => {
        
        const target = e.target;
        let name = target.name;
        let value = null;

        if (target.type === 'checkbox') {
            
            const options = [...this.state.allowed_plans]
            let index;
            if (target.checked) {
                options.push(target.value)
            } else {
                index = options.indexOf(target.value)
                options.splice(index, 1)
            }
            this.setState({ membership_types: options });
            return;
            
        } else if (target.type === 'file') {
            value = target.files[0];
        } else if (target.multiple === true) {
            let options = Array.from(e.target.selectedOptions, option => option.value);
            value = options.filter(function (el) {
                return el != null && el != "" && el != undefined;
            });
        }
        else {
            value = target.value;
        }
        this.setState({
            [name]: value
        });
    }

    handleFileInputChange = (event) => {
        const input = event.target;

        if (input.files && input.files[0]) {
            this.setState({bannerFile: input.files[0]})
            var reader = new FileReader();
            
            reader.onload = (e) => {
                this.setState({banner: e.target.result})
                $(".banner-upload-button").show();
                // $('.banner-preview')
                //     .attr('src', e.target.result)
            };
            reader.readAsDataURL(input.files[0]);
        }
    }

    uploadBanner = () => {
        this.setState({loading: true})
        let formData = new FormData();
        formData.append('banner', this.state.bannerFile);
        httpClient.post(`events/upload-image/${this.state.event.id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data;',
            }
        }).then(res => {
            this.setState({ loading: false });
            toast.success("Banner updated successfully");
            const { history } = this.props;
            history.push(`/admin/events/${this.eventId}/view`);
        }).catch(err => {
            this.setState({ loading: false });
            toast.error("There was an error uploading the event banner")
        })
    }

    submitForm = (e) => {
        e.preventDefault();
        const checkedBoxNodes = Array.from(document.querySelectorAll('input[type=checkbox]:checked'));
        let checkedBoxes = checkedBoxNodes.map(checkBox => checkBox.id)
        
        
        let data = {
            title: this.state.title,
            description: this.state.description,
            // location: this.state.location,
            location: this.state.locations,
            start_date: this.state.start_date,
            end_date: this.state.end_date,
            membership_types: checkedBoxes
        }
        httpClient.put(`events/${this.eventId}`, data).then(res => {
            
            toast.success("Event updated successfully");
            const { history } = this.props;
            history.push(`/admin/events/${this.eventId}/view`);
            

        }).catch(err => {
            if (err.response && err.response.status == 400) {

                let formErrors = err.response.data.message;
                if (Array.isArray(formErrors) || (typeof formErrors === 'object' && formErrors !== null)) {
                    formErrors = Object.values(formErrors).flat();
                    this.setState({ validationErrors: formErrors })
                } else {
                    formErrors = formErrors.split(",");
                    formErrors = formErrors.map(item => trim(item));
                    this.setState({ validationErrors: formErrors })
                }
                toast.error("Please fill all required fields");
            } else {
                if (err.response && err.response.data.message != undefined) {
                    const errorMessage = [err.response.data.message];
                    this.setState({ validationErrors: errorMessage });
                }
            }
            toast.error("An error occured, please try again");
            this.setState({ loading: false });
        })
        
    }

    render = () => {
        return (
            <div>
                <AdminLayout page_title="Edit event">
                    <div className="">
                        {this.state.event != null && <div className="row">
                            <div className="col-md-4" style={{ position: "relative" }}>
                                <img src={this.state.banner} className="img-fluid banner-preview" alt="event banner"/>
                                
                                <div style={{
                                    top: 0,
                                    right: 0,
                                    position: "absolute",
                                    alignItems: "center",
                                    display: "flex",}}>
                                    <input type="file" accept="image/*" style={{
                                            height: "100%",
                                            left: 0,
                                            position: "absolute",
                                            width: "100%",
                                            opacity: 0}}
                                            onChange={(e) => this.handleFileInputChange(e)} />

                                    <div className="bg-primary rounded p-1 text-white"><i className="far fa-edit"></i> Change</div>
                                </div>
                                <div className="text-center mt-3 banner-upload-button" style={{ display:"none" }}>
                                    <ReactLoader loaded={!this.state.loading}>
                                        <button className="btn btn-danger" onClick={(e) => this.uploadBanner(e)}>Update banner</button>
                                    </ReactLoader>
                                </div>
                            </div>
                            <div className="col col-md-8">
                                <form onSubmit={this.submitForm}>
                                    {this.state.validationErrors.length > 0 &&
                                        <ul className="list-group">
                                            {this.state.validationErrors.map((error) => {
                                                return (<li className="list-group-item list-group-item-danger" key={error}>{error.charAt(0).toUpperCase() + error.slice(1)}</li>)
                                            })}
                                        </ul>
                                    }
                                    <div className="form-group">
                                        <label htmlFor="Event Title">Event title</label>
                                        <input type="text" className="form-control" placeholder="Name of event" name="title" value={this.state.title} onChange={this.handleInputChange}/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="Event description">Event description</label>
                                        <textarea className="form-control" name="description" placeholder="Description of event" value={this.state.description} onChange={this.handleInputChange}></textarea>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="Date">Date and Time</label>
                                        <input className="form-control" type="datetime-local" name="start_date" value={dayjs(this.state.start_date).format("YYYY-MM-DDTHH:mm")} onChange={this.handleInputChange}/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="End date">End date</label>
                                        <input type="datetime-local" name="end_date" min={dayjs(this.state.start_date).format("YYYY-MM-DD")} id="end_date" className="form-control" value={dayjs(this.state.end_date).format("YYYY-MM-DDTHH:mm")} onChange={this.handleInputChange} />
                                    </div>
                                    {/* <div className="form-group">
                                        <label htmlFor="location">Location of event</label>
                                        <input className="form-control" name="location" type="text" placeholder="Phyiscal address or web url" value={this.state.location} onChange={this.handleInputChange}/>
                                    </div> */}
                                    {this.state.locations.map((template, index) => {
                                        return (
                                            <div key={index}>
                                                <div className="border rounded p-3">
                                                    <div className="mb-3 float-right">
                                                        <button className="btn btn-success btn-sm" type="button" onClick={this.addNewLocationField}>Add</button>
                                                        {this.state.locations.length > 1 && <button className="btn btn-danger btn-sm" type="button" onClick={() => this.removeLocationField(index)}>Remove</button>}
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="location">Location</label>
                                                        <input className="form-control" value={template.location} name="location" type="text" placeholder="Phyiscal address or web url" onChange={(e) => this.handleLocationDateInputChange(index, e)} required />
                                                    </div>
                                                    <div className="form-row">
                                                        <div className="col col-md-6 form-group">
                                                            <label htmlFor="Start date">Start date</label>
                                                            <input className="form-control" value={dayjs(template.start_date).format("YYYY-MM-DDTHH:mm")} type="datetime-local" min={dayjs(this.state.start_date).format("YYYY-MM-DDTHH:mm")} name="start_date" onChange={(e) => this.handleLocationDateInputChange(index, e)} required />
                                                        </div>
                                                        <div className="col col-md-6 form-group">
                                                            <label htmlFor="End date">End date</label>
                                                            <input type="datetime-local" name="end_date" value={dayjs(template.end_date).format("YYYY-MM-DDTHH:mm")} min={dayjs(this.state.locations[index].start_date).format("YYYY-MM-DDTHH:mm")} max={dayjs(this.state.end_date).format("YYYY-MM-DDTHH:mm")} id="end_date" className="form-control" onChange={(e) => this.handleLocationDateInputChange(index, e)} required />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        )
                                    })}
                                    {/* <div className="form-group">
                                        <label htmlFor="Membership types">Select which members can attend this event <small>(Leave blank to allow all member types)</small></label>
                                        {this.state.allowed_plans.map((plan) => {
                                            return (
                                                <div key={plan.id}>
                                                    <label htmlFor={plan.id}>
                                                        <input type="checkbox" name="membership_types" value={plan.id} id={plan.id} defaultChecked={plan.checked}/>
                                                        <span> {plan.plan_name}</span>
                                                    </label>
                                                </div>
                                            )
                                        })}
                                    </div> */}
                                    <div>
                                        <button className="btn btn-primary" type="submit">Update event</button>
                                    </div>
                                </form>
                            </div>
                        </div>}
                    </div>
                </AdminLayout>
            </div>
        )
    }
}