import dayjs from "dayjs";
import { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import httpClient from "../../../httpClient";
import AdminLayout from "../../layouts/admin";

export default class ShowCompetition extends Component{
    constructor(props){
        super(props)
        this.competitionId = this.props.match.params.id;
    }

    state = {
        competition: {},
        attendees: [],
        winner: null
    }

    fetchCompetition = () => {
        return httpClient.get(`competitions/${this.competitionId}`).then(res => {
            let competition = res.data.data;
            this.setState({ competition });
            return competition;
        })
    }

    fetchAttendees = () => {
        return httpClient.get(`competitions/${this.competitionId}/registrations`).then(res => {
            let attendees = res.data.data;
            this.setState({ attendees });
        }).catch(err => {
            console.log(err);
            console.log("There was an error fetching attendees");
        })
    }

    fetchWinningUser = (userId) => {
        httpClient.get(`users/${userId}`).then(res => {
            let member = res.data.data;
            this.setState({winner: member});
        }).catch(err => {
            toast.error("There was a problem fetching the competition winner");
        })
    }

    makeUserWinner = (index) => {
        if (!window.confirm("Are you sure you want to make this member the winner?")) {
            return;
        }
        let member = this.state.attendees[index].user;
        httpClient.post(`competitions/${this.state.competition.id}/winner/${member.id}`).then(res => {
            toast.success("Member has been marked as the competition winner");
            setTimeout(() => {
                window.location.reload();
            }, 1500);
        }).catch(err => {
            toast.error("There was an error making that member a winner");
        })
    }
    componentDidMount(){
        this.fetchCompetition().then(data => {
            if (data.winnerId != null){
                this.fetchWinningUser(data.winnerId);
            }
            
        });
        this.fetchAttendees()
    }

    render(){
        return (
            <div>
                <AdminLayout page_title="View Competition">
                    <div className="row">
                        <div className="col-12 col-md-9">
                            <div className="table-responsive">
                                <p className="font-weight-bold">Participant Entries</p>
                                <table className="table table-bordered table-striped">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>Username</th>
                                            <th>Firstname</th>
                                            <th>Lastname</th>
                                            <th>Phone</th>
                                            <th>Age</th>
                                            <th>Gender</th>
                                            <th>Entry</th>
                                            <th>Actions</th>
                                            {/* <th>City</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.attendees.map((member, index) => {
                                            return (<tr key={member.user.id}>
                                                <td><Link to={`/admin/members/${member.user.id}/view`}>
                                                    {member.user.username}
                                                </Link></td>
                                                <td>{member.user.firstname}</td>
                                                <td>{member.user.lastname}</td>
                                                <td>{member.user.phoneNumber}</td>
                                                <td title={dayjs(member.user.dob).format("YYYY-MM-DD")}>{dayjs().diff(member.user.dob, 'years')} years old</td>
                                                <td>{member.user?.gender.charAt(0).toUpperCase() + member.user?.gender.slice(1)}</td>
                                                <td>{member.entry != null ? <a href={member.entry} target="_blank">View entry</a> : "No entry uploaded"}</td>
                                                {this.state.competition.winnerId == null ? <td>
                                                    <button className="btn btn-primary" onClick={() => this.makeUserWinner(index)}>Make winner</button>
                                                </td> : <td>

                                                </td>}
                                                {/* <td>{member.user.address.state}</td> */}
                                            </tr>)
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-12 col-md-3">
                            <h5 className="font-weight-bold">Competition</h5>
                            <p className="text-secondary">{this.state.competition.title}</p>
                            <h5 className="font-weight-bold">Description/Instructions</h5>
                            <p className="text-secondary" dangerouslySetInnerHTML={{ __html: this.state.competition.description }}></p>
                            <h5 className="font-weight-bold">Opening date</h5>
                            <p className="text-secondary">{dayjs(this.state.competition.opening_date).format("YYYY-MM-DD HH:mm")}</p>
                            <h5 className="font-weight-bold">Closing date</h5>
                            <p className="text-secondary">{dayjs(this.state.competition.closing_date).format("YYYY-MM-DD HH:mm")}</p>
                            <h5 className="font-weight-bold">Open to</h5>
                            {(this.state.competition.allowed_plans == null || this.state.competition.allowed_plans.length < 1) ? <p className="text-secondary">All members</p> : <div>
                                <ul className="list">
                                    {this.state.competition.allowed_plans.map((plan) => {
                                        return (
                                            <li key={plan.id} className="text-secondary">{plan.plan_name} members</li>
                                        )
                                    })}
                                </ul>
                            </div>}
                            <h5 className="font-weight-bold">Winner</h5>
                            <p className="text-secondary">{this.state.winner != null ? <Link to={`/admin/members/${this.state.winner.user_id}/view`}>
                                {this.state.winner.firstname + " " + this.state.winner.lastname}
                            </Link> : 'Not declared yet'}</p>
                            <h5 className="font-weight-bold"></h5>
                            <p className="text-secondary"></p>
                            <h5 className="font-weight-bold"></h5>
                            <p className="text-secondary"></p>
                            <Link className="btn btn-primary" to={`/admin/competitions/${this.state.competition.id}/edit`}>Edit</Link>
                        </div>
                    </div>
                </AdminLayout>
            </div>
        )
    }
}