import React, { Component } from "react";
import AdminLayout from "../../layouts/admin";
import httpClient from "../../../httpClient";
import { toast } from "react-toastify";
import Auth from "../../../helpers/auth";
import { Redirect } from "react-router-dom";


var Loader = require('react-loader');
export default class CreatePlan extends Component {
    constructor(props){
        super(props);
        this.state = {
            // editorState: EditorState.createEmpty(),
            "price": "",
            // "valid_from": "2021-02-20T03:24:46.889Z",
            // "valid_to": "2021-02-20T03:25:14.122Z",
            "plan_name": "",
            "is_active": "true",
            description: "",
            loading: false,
            validationErrors: []
        }
        
    }
    
    handleInpuChange = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }
    createPlan = (e) => {
        e.preventDefault();
        this.setState({loading: true});
        let plan = {
            plan_name: this.state.plan_name,
            description: this.state.description,
            price: this.state.price,
            "valid_from": "2021-02-20T03:24:46.889Z",
            "valid_to": "2021-02-20T03:25:14.122Z",
            "is_active": "true",
        }
        
        httpClient.post("/plans", plan).then(res => {
            toast.success("Plan has been added successfully");
            this.setState({ loading: false });
            const {history} = this.props;
            history.push("/admin/plans");
            
        }).catch(err => {
            if (err.response && err.response.status == 400) {

                let formErrors = err.response.data.message;
                if (Array.isArray(formErrors) || (typeof formErrors === 'object' && formErrors !== null)) {
                    formErrors = Object.values(formErrors).flat();
                    this.setState({ validationErrors: formErrors })
                } else {
                    formErrors = formErrors.split(",");
                    this.setState({ validationErrors: formErrors })
                }
            } else {
                if (err.response && err.response.data.message != undefined) {
                    const errorMessage = [err.response.data.message];
                    this.setState({ validationErrors: errorMessage });
                }
            }
            toast.error("There was an error creating that plan, please try again");
            this.setState({ loading: false });
        })
        
    }
    render() {
        return (
            <div className="">
                <AdminLayout page_title="Add new membership plan">
                    <div className="container-fluid mt-5">
                        <div className="row">
                            <div className="col-md-2"></div>
                            <div className="col col-md-8">
                                <form onSubmit={this.createPlan}>
                                    {this.state.validationErrors.length > 0 &&
                                        <ul className="list-group">
                                            {this.state.validationErrors.map((error) => {
                                                return (<li className="list-group-item list-group-item-danger" key={error.toString()}>{error}</li>)
                                            })}
                                        </ul>
                                    }
                                    <div className="form-row">
                                        <div className="col form-group">
                                            <label htmlFor="title">Membership title</label>
                                            <input className="form-control" name="plan_name" placeholder="Membership name e.g Gold" onChange={this.handleInpuChange} required/>
                                        </div>
                                        <div className="col form-group">
                                            <label htmlFor="price">Price of plan (NGN)</label>
                                            <input className="form-control" type="number" step="any" name="price" onChange={this.handleInpuChange} required/>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col form-group">
                                            <label htmlFor="Plan description">Plan description</label>
                                            <textarea className="form-control" placeholder="Description/features of the plan" name="description" onChange={this.handleInpuChange} required></textarea>
                                        </div>
                                    </div>
                                    <div>
                                        <Loader loaded={!this.state.loading}>
                                            <button type="submit" className="btn btn-primary">Create plan</button>
                                        </Loader>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </AdminLayout>
            </div>
        )
    }
}