import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import AdminLogin from "./views/admin/admin-login";
import AdminSignup from "./views/admin/admin-signup";
import ListCompetitions from "./views/admin/competitions";
import CreateCompetition from "./views/admin/competitions/create";
import ShowCompetition from "./views/admin/competitions/show";
import ListSubscriptions from "./views/admin/subscriptions";
import AdminDashboard from "./views/admin/dashboard";
import ListEvents from "./views/admin/events";
import CreateEvent from "./views/admin/events/create";
import EditEvent from "./views/admin/events/edit";
import ShowEvent from "./views/admin/events/show";
import ListMembers from "./views/admin/members";
import CreateMember from "./views/admin/members/create";
import BulkUploadMembers from "./views/admin/members/upload";
import ViewMember from "./views/admin/members/view";
import ListPlans from "./views/admin/membership_plans";
import CreatePlan from "./views/admin/membership_plans/create";
import Login from "./views/auth/login";
import SignupMember from "./views/auth/signup";
import Home from "./views/home";
import UserDashboard from "./views/users/dashboard";
import EditCompetition from "./views/admin/competitions/edit";
import ViewStickers from "./views/admin/stickers";
import About from "./views/static-pages/about";
import Terms from "./views/static-pages/terms";
import Privacy from "./views/static-pages/privacy";
import CreateAnnouncement from "./views/admin/announcements/create";
import ListAnnouncements from "./views/admin/announcements";

class Routes extends React.Component{
    render(){
        return(
            <Switch>
                <Route path="/" component={Login} exact/>
                <Route path="/signup" component={SignupMember} exact />
                <Route path="/login" component={Login}/>
                <Route path="/dashboard" exact component={UserDashboard}/>

                <Route path="/pages/about" exact component={About}/>
                <Route path="/pages/terms" component={Terms} exact />
                <Route path="/pages/privacy-policy" component={Privacy} exact/>

                <Route path="/admin" component={AdminDashboard} exact/>
                <Route path="/admin/signup" component={AdminSignup} exact />
                <Route path="/admin/login" component={AdminLogin} exact />
                <Route path="/admin/dashboard" component={AdminDashboard} exact />
                <Route path="/admin/members" component={ListMembers} exact />
                <Route path="/admin/members/create" component={CreateMember} exact />
                <Route path="/admin/members/bulk-create" component={BulkUploadMembers} exact/>
                <Route path="/admin/members/:id/view" component={ViewMember} exact />
                <Route path="/admin/subscriptions" component={ListSubscriptions} exact/>
                <Route path="/admin/plans" component={ListPlans} exact/>
                <Route path="/admin/plans/create" component={CreatePlan} exact />
                <Route path="/admin/events" component={ListEvents} exact />
                <Route path="/admin/events/create" component={CreateEvent} exact />
                <Route path="/admin/events/:id/view" component={ShowEvent} exact />
                <Route path="/admin/events/:id/edit" component={EditEvent} exact />
                <Route path="/admin/competitions" component={ListCompetitions} exact />
                <Route path="/admin/competitions/create" component={CreateCompetition} exact />
                <Route path="/admin/competitions/:id/view" component={ShowCompetition} exact />
                <Route path="/admin/competitions/:id/edit" component={EditCompetition} exact/>
                <Route path="/admin/stickers" component={ViewStickers} exact/>
                <Route path="/admin/announcements/create" exact component={CreateAnnouncement} />
                <Route path="/admin/announcements" exact component={ListAnnouncements}/>
            </Switch>
        );
    }
}

export default Routes;