/**
 * @fileOverview An helper file to help you easily manipulate and deal with authentication when using token.
 * @author Elusoji Sodeeq<sdkcodes@gmail.com>
 * @version 1.0.0
 */
class Auth {
    static isLoggedIn() {
        return Auth.tokenPresent();
    }

    static isAdmin() {
        return Auth.userIsOfType('super_admin');
    }

    static isSubAdmin(){
        return Auth.userIsOfType('sub_admin');
    }

    static isMember(){
        return Auth.userIsOfType('member');
    }

    static userIsOfType(type){
        return Auth.user().role === type;
    }

    static tokenPresent() {
        return Auth.getAuthToken() != null;
    }

    static getAuthToken() {
        return localStorage.getItem("auth_token");
    }

    static user() {
        return JSON.parse(localStorage.getItem('user'));
    }

    static logout() {
        localStorage.clear();
    }

    static saveToken(token) {
        localStorage.setItem("auth_token", token);
    }

    static saveUser(user) {
        return localStorage.setItem("user", JSON.stringify(user));
    }

    static updateUser(properties){
        let user = Auth.user();
        localStorage.removeItem("user");
        return Auth.saveUser({...user, ...properties});
        // return Auth.saveUser(Object.assign(user, properties));
    }

}

export default Auth
