import React from "react";
import ReactLoader from "react-loader";
import { Link, Redirect } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Auth from "../../../helpers/auth";
import httpClient from "../../../httpClient";
import AdminLayout from "../../layouts/admin";

export default class BulkUploadMembers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            members: [
                // {id: 1, first_name: "Sodeeq", last_name: "Olamide", phone: "081234839374"},
                // { id: 2, first_name: "Jane", last_name: "Doe", phone: "09082728372" }
            ],
            errors: [],
            loading: false,
            bulk_upload_file: null
        }
    }

    componentDidMount() {
        
    }
    
    uploadBulkFile = () => {
        this.setState({ loading: true, errors: [] })
        let formData = new FormData();
        formData.append('members', this.state.bulk_upload_file);
        httpClient.post(`users/bulk-upload-members`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data;',
            }
        }).then(res => {
            this.setState({ loading: false });
            toast.success("File updated successfully");
            setTimeout(() => {
                const { history } = this.props;
                history.push("/admin/members");
            }, 2000);
        }).catch(err => {
            this.setState({ loading: false });
            toast.error("There was an error uploading the file")
            if (err.response.status != undefined && err.response.status == 409){
                this.setState({errors: err.response.data.errors})
            }
        })
    }
    handleInputChange = (e) => {
        const target = e.target;
        let name = target.name;
        let value = null;
        if (target.type === 'checkbox') {
            value = target.checked;
        } else if (target.type === 'file') {
            value = target.files[0];
        } else {
            value = target.value;
        }
        this.setState({
            [name]: value
        });
    }

    render() {
        return (
            <div className="">
                <AdminLayout page_title={`Upload members from spreadsheet`}>
                    <div className="table-responsive">
                        
                        <div>
                            <div className="alert alert-warning">
                                <strong><i className="fa fa-exclamation-circle"></i> Important information before upload</strong>
                                <br/>
                                <ul>
                                    <li>
                                        Spreadsheet should only contain the following columns as headings in order
                                        <br/>
                                        ['firstname', 'lastname', 'email', 'password', 'username', 'phoneNumber', 'dob', 'school', 'gender', 'street', 'state', 'lga']
                                    </li>
                                    <li>
                                        Dates (e.g date of birth) should be in the format "DD-MM-YYY" i.e 22-03-2010
                                    </li>
                                    <li>
                                        Gender should be either 'male' or 'female'.
                                    </li>
                                    <li>
                                        Phone number should be stored in string format (with the leading 0).
                                    </li>
                                    <li>
                                        Phone numbers must be of 11 characters in length.
                                    </li>
                                    <li>
                                        All rows must have a value for the columns listed above.
                                    </li>
                                    <li>
                                        Username of members must be unique
                                    </li>
                                    <li>
                                        For optimum performance, uploads should be done in batches of <strong>not more than 1000</strong> records at a time
                                    </li>
                                </ul>
                            </div>
                            <input type="file" name="bulk_upload_file" id="bulk_upload_file" accept=".xls,.xlsx" onChange={this.handleInputChange} />
                            <button className="btn" onClick={this.uploadBulkFile}>Upload</button>
                        </div>
                        <ReactLoader loaded={!this.state.loading}>
                            {this.state.errors.length > 0 && <div>
                                <p className="text-danger">
                                    There's a problem with {this.state.errors.length} rows.
                                    <br/>
                                    Check the top top 100 errors below to give you an idea what went wrong.
                                </p>
                                <table className="table table-striped">
                                    <caption>Showing {this.state.members.length} of {this.state.membersPagination?.total ?? 0} members</caption>
                                    <tbody>
                                        <tr>
                                            <th>Problem(s)</th>
                                            <th>Row</th>
                                            
                                        </tr>
                                        {this.state.errors.slice(0, 100).map((error, index) => {
                                            return (<tr key={error.row}>
                                                <td>{error.message}</td>
                                                <td>{error.row}</td>
                                            </tr>)
                                        })}
                                    </tbody>
                                </table>
                            </div>}
                        </ReactLoader>

                    </div>
                </AdminLayout>
            </div>
        )
    }
}