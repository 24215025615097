import React from "react";
import httpClient from "../../httpClient";
import { Link, useLocation } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Auth from "../../helpers/auth";
import { internationalizePhoneNumber } from "../../utils";
import { trim } from "jquery";
var Loader = require('react-loader');
const states_and_lgas = require("../../helpers/statesandlgas.json");
class SignupMember extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            identifier: "",
            password: "",
            firstname: "",
            lastname: "",
            username: "",
            date_of_birth: "",
            gender: "",
            school_name: "",
            address: "",
            state: "",
            lga: "",
            step: 0,
            states_list: states_and_lgas,
            lgas_list: [],
            validationErrors: [],
            loading: false,
            passType: "password"
        }
        this.doSignup = this.doSignup.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleStateInputChange = this.handleStateInputChange.bind(this);
    }
    componentDidMount(){
        
    }
    render(){
        require("../../assets/css/loginstyle.css")
        return (
            <div className="container-fluid">
                <div className="bodyWrapper">
                    <div className="row">
                        <div className="col col-sm-6 text-center">
                            <Link to="/">
                                <img src={process.env.PUBLIC_URL + '/logo.png'} className="bodyLogo" />
                            </Link>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col d-none d-md-block">
                            <img src={require("../../assets/img/memberauth/signupsideimage.png").default} className="signupSideImage"/>
                        </div>
                        
                        <div className="col">
                            <div className="">
                                <form className="validate-form indomitableForm" onSubmit={this.doSignup}>
                                    <h2 className="signupHeroHeader">
                                        Jump right in!
                                    </h2>
                                    <p className="signupHeroSubHeader">Create your account</p>
                                        {this.state.validationErrors.length > 0 &&
                                            <ul className="list-group">
                                                {this.state.validationErrors.map((error) => {
                                                    return (<li className="list-group-item list-group-item-danger">{error.charAt(0).toUpperCase() + error.slice(1)}</li>)
                                                })}
                                            </ul>

                                        }
                                        <div className="registration-step step-1">
                                            <div className="wrap-input100 validate-input" data-validate="Valid email is required: ex@abc.xyz">
                                                <input className="input100" type="tel" name="identifier" placeholder="Phone number" value={this.state.identifier} onChange={this.handleInputChange} required />
                                                <label htmlFor="phone number" className="control-label">Phone number</label>
                                                <span className="focus-input100-1"></span>
                                                <span className="focus-input100-2"></span>
                                            </div>
                                            <div className="wrap-input100 validate-input" data-validate="First name is required">
                                                <input className="input100" type="text" name="firstname" placeholder="First name" value={this.state.firstname} onChange={this.handleInputChange} required />
                                                <label className="control-label" htmlFor="firstname">First name</label>
                                                <span className="focus-input100-1"></span>
                                                <span className="focus-input100-2"></span>
                                            </div>
                                            <div className="wrap-input100 validate-input" data-validate="First name is required">
                                                <input className="input100" type="text" name="lastname" placeholder="Last name" value={this.state.lastname} onChange={this.handleInputChange} required />
                                                <label className="control-label" htmlFor="lastname">Last name</label>
                                                <span className="focus-input100-1"></span>
                                                <span className="focus-input100-2"></span>
                                            </div>
                                            <div className="wrap-input100 validate-input" data-validate="Username is required and should be unique">
                                                <input className="input100" type="text" name="username" placeholder="username" value={this.state.username} onChange={this.handleInputChange} required />
                                                <label htmlFor="username" className="control-label">Username</label>
                                                <span className="focus-input100-1"></span>
                                                <span className="focus-input100-2"></span>
                                            </div>
                                            <div className="wrap-input100 rs1 validate-input" data-validate="Password is required">
                                                <a href="#" style={{ position: "absolute", right: "5%", top: "30%" }} onClick={e => {
                                                    e.preventDefault();
                                                    if (this.state.passType == 'password') {
                                                        this.setState({ passType: 'text' });
                                                    } else {
                                                        this.setState({ passType: 'password' });
                                                    }
                                                }}><i className={'fa fa-' + (this.state.passType == 'password' ? 'eye' : 'eye-slash')}></i></a>
                                                <input className="input100" type={this.state.passType} name="password" placeholder="Password" value={this.state.password} onChange={this.handleInputChange} required />
                                                <label className="control-label" htmlFor="password">Password</label>
                                                <span className="focus-input100-1"></span>
                                                <span className="focus-input100-2"></span>
                                            </div>
                                        </div>
                                        <div className="registration-step step-2">
                                            <div className="wrap-input100 validate-input">
                                                <input className="input100" type="date" name="date_of_birth" value={this.state.date_of_birth} onChange={this.handleInputChange} required />
                                                <label className="control-label" htmlFor="dateofbirth">Date of birth</label>
                                                <span className="focus-input100-1"></span>
                                                <span className="focus-input100-2"></span>
                                            </div>
                                            <div className="wrap-input100 validate-input">
                                                <select className="input100" name="gender" value={this.state.gender} onChange={this.handleInputChange} required>
                                                    <option value="">Select your gender</option>
                                                    <option value="female">Female</option>
                                                    <option value="male">Male</option>
                                                </select>
                                                <label htmlFor="gender" className="control-label">I am a</label>
                                                <span className="focus-input100-1"></span>
                                                <span className="focus-input100-2"></span>
                                            </div>
                                            <div className="wrap-input100 validate-input">
                                                <input className="input100" name="school_name" value={this.state.school_name} onChange={this.handleInputChange} placeholder="Name of your school" required />
                                                <label htmlFor="school_name" className="control-label">Name of your school</label>
                                                <span className="focus-input100-1"></span>
                                                <span className="focus-input100-2"></span>
                                            </div>
                                            <div className="wrap-input100 validate-input">
                                                <input className="input100" name="address" value={this.state.address} placeholder="Home street address" onChange={this.handleInputChange} required />
                                                <label htmlFor="address" className="control-label">Street address</label>
                                                <span className="focus-input100-1"></span>
                                                <span className="focus-input100-2"></span>
                                            </div>
                                            <div className="wrap-input100 validate-input">
                                                <select className="input100" name="state" value={this.state.state} onChange={this.handleStateInputChange} required>
                                                    <option value="">Select state</option>
                                                    {this.state.states_list.map((item) => {
                                                        return <option value={item.state.name} key={item.state.id}>{item.state.name}</option>
                                                    })}
                                                </select>
                                                <label className="control-label" htmlFor="state">Select state</label>
                                            </div>
                                            <div className="wrap-input100 validate-input">
                                                <select className="input100" name="lga" value={this.state.lga} onChange={this.handleInputChange} required>

                                                    {this.state.lgas_list.map((lga) => {
                                                        return <option key={lga.name}>{lga.name}</option>
                                                    })}
                                                </select>
                                                <label htmlFor="lga" className="control-label">Select local government area</label>
                                            </div>
                                            <div className="form-check mt-3">
                                                <input className="form-check-input" type="checkbox" value="agree" id="terms" name="terms" style={{height: "initial"}}/>
                                                <label className="form-check-label" htmlFor="terms">
                                                    I agree with the <a href="/pages/terms" target="_blank" rel="noopener">terms and conditions</a>
                                                </label>
                                            </div>
                                        </div>

                                        
                                        <Loader loaded={!this.state.loading}>
                                            <div className="mt-4">
                                                <button className="btn-block" type="submit">
                                                    Register
                                                </button>
                                            </div>
                                        </Loader>

                                        <div className="alternativeActionText mt-3" style={{ textAlign: "center" }}>
                                            Already have an account? <Link to="/login">Sign in</Link>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-center">
                        <Link to="/pages/terms">
                            Terms & Conditions
                        </Link>
                        &nbsp;&nbsp;&nbsp;
                        <Link to="/pages/privacy-policy">
                            Privacy policy
                        </Link>
                    </div>
                <ToastContainer/>  
            </div>
        );
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleStateInputChange(event){
        const target = event.target;
        const value = target.value;
        this.setState({state: value});
        console.log("state", value);
        const selectedState = this.state.states_list.find(item => {
            return item.state.name == value;
        });
        
        if (selectedState != undefined){
            this.setState({ lgas_list: selectedState.state.locals });
        }else{
            this.setState({lgas_list: []});
        }
        
    }

    doSignup(e){
        let termsCheckbox = document.querySelector("input[name=terms]");
        console.log("terms", termsCheckbox.value);
        if (termsCheckbox.checked != true){
            toast.error("You need to agree with the terms and conditions to signup");
            e.preventDefault();
            return false;
        }
        
        this.setState({validationErrors: [], loading: true})
        e.preventDefault();
        
        const signupData = {
            phoneNumber: internationalizePhoneNumber(this.state.identifier),
            password: this.state.password,
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            username: this.state.username,
            dob: this.state.date_of_birth,
            gender: this.state.gender,
            address: {
                street: this.state.address,
                state: this.state.state,
                lga: this.state.lga,
            },
            school: this.state.school_name
        }
        
        httpClient.post("auth/signup", signupData).then(res => {
            toast.success("Your account has been created successfully. Complete your profile by uploading a profile picture and choosing a membership plan");
            const resData = res.data.data;
            const token = resData.token;
            const user = resData.user;
            Auth.saveToken(token);
            Auth.saveUser(user);
            const { history } = this.props;
            // history.push("/dashboard");
            window.location.href = "/dashboard";
        }).catch(err => {
            toast.error("There was a problem creating your account");
            console.log(err.response)
            
            if (err.response && err.response.status == 400){

                let formErrors = err.response.data.message;
                if (Array.isArray(formErrors) || (typeof formErrors === 'object' && formErrors !== null)){
                    formErrors = Object.values(formErrors).flat();
                    this.setState({validationErrors: formErrors})
                }else{
                    formErrors = formErrors.split(",");
                    formErrors = formErrors.map(item => trim(item));
                    this.setState({ validationErrors: formErrors })
                }
                toast.error("Please fill all required fields");
            }else{
                if (err.response && err.response.data.message != undefined) {
                    const errorMessage = [err.response.data.message];
                    this.setState({ validationErrors: errorMessage });
                }
            }
        }).finally(response => {
            this.setState({loading: false});
        })
    }
}

export default SignupMember;

