import React, { Component } from "react";
import { config } from "../appconfig";
// import "../assets/landing/css/style.css"
// import "../assets/landing/vendors/fontawesome-free/css/all.min.css"
import logo from "../assets/landing/images/logo.png"
import { Link } from "react-router-dom";
class Home extends Component{
    render(){
        return (
            <div>
                <header className="foi-header landing-header">
                    <div className="container">
                        <nav className="navbar navbar-expand-lg navbar-light foi-navbar" style={{ background: "none", border: "none" }}>
                            <a className="navbar-brand" href="index.html">
                                <img src={logo} alt={config.app_name} style={{width:"70px"}}/>
                            </a>
                            <div className="ml-auto">
                                <Link to="/signup" className="btn btn-light nav-item">Sign up</Link>
                                <Link to="/login" className="btn btn-light nav-item">Log in</Link>
                            </div>
                            {/* <button className="navbar-toggler d-lg-none" type="button" data-toggle="collapse" data-target="#collapsibleNavId" aria-controls="collapsibleNavId" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="collapsibleNavId">
                                <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
                                    <li className="nav-item active">
                                        <a className="nav-link" href="index.html">Home <span className="sr-only">(current)</span></a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="about.html">About</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="features.html">Features</a>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle" href="#" id="pagesMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Pages</a>
                                        <div className="dropdown-menu" aria-labelledby="pagesMenu">
                                            <a className="dropdown-item" href="blog.html">Blog</a>
                                            <a className="dropdown-item" href="login.html">Login</a>
                                            <a className="dropdown-item" href="register.html">Register</a>
                                            <a className="dropdown-item" href="faq.html">FAQ</a>
                                            <a className="dropdown-item" href="404.html">404</a>
                                            <a className="dropdown-item" href="careers.html">Careers</a>
                                            <a className="dropdown-item" href="blog-single.html">Single blog</a>
                                            <a className="dropdown-item" href="privacy-policy.html">Privacy policy</a>
                                        </div>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="contact.html">contact</a>
                                    </li>
                                </ul>
                                <ul className="navbar-nav mt-2 mt-lg-0">
                                    <li className="nav-item mr-2 mb-3 mb-lg-0">
                                        <a className="btn btn-secondary" href="register.html">Sign up</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="btn btn-secondary" href="login.html">Login</a>
                                    </li>
                                </ul>
                            </div> */}
                        </nav>
                        <div className="header-content">
                            <div className="row">
                                <div className="col-md-6">
                                    <h1>Great app that makes your life awesome</h1>
                                    <p className="text-dark">The BrightMinds App for the Indomie Fan Club has everything you need to enjoy your benefits has a club member.</p>
                                    <Link className="btn btn-primary mb-4" to="/signup">Get Started</Link>
                                    <div className="my-2">
                                        <p className="header-app-download-title">GET OUR MOBILE APP</p>
                                    </div>
                                    <div>
                                        <button className="btn btn-app-download mr-2"><img src={require("../assets/landing/images/ios.svg").default} alt="App store"/></button>
                                        <button className="btn btn-app-download"><img src={require("../assets/landing/images/android.svg").default} alt="play store"/></button>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <img src={require("../assets/landing/images/app_1.png").default} alt="landing app" width="388px" className="img-fluid"/>
                                    </div>
                                </div>
                        </div>
                    </div>
                </header>
                <section className="py-5 mb-5">
                    <div className="container">
                        <h2 className="section-title mb-5">Application Features</h2>
                        <div className="row">
                            <div className="col-lg-4 mb-4 mb-lg-0">
                                <h5>Easy Registration</h5>
                                <p className="text-dark">No matter what kind of home you have to share, you can increase your earnings.</p>
                                <p className="mb-5"><a href="#!" className="text-primary mb-5">Find out More</a></p>
                                <h5>Events Notifications</h5>
                                <p className="text-dark">No matter what kind of home you have to share, you can increase your earnings.</p>
                                <p className="mb-5"><a href="#!" className="text-primary mb-5">Find out More</a></p>
                            </div>
                            <div className="col-lg-4 mb-3 mb-lg-0">
                                <h5>Competition Participation</h5>
                                <p className="text-dark">No matter what kind of home you have to share, you can increase your earnings.</p>
                                <p className="mb-5"><a href="#!" className="text-primary mb-5">Find out More</a></p>
                                <h5>Online Shopping Store</h5>
                                <p className="text-dark">No matter what kind of home you have to share, you can increase your earnings.</p>
                                <p className="mb-5"><a href="#!" className="text-primary mb-5">Find out More</a></p>
                            </div>
                            <div className="col-lg-4">

                            </div>
                        </div>
                    </div>
                </section>
                {/* <section className="py-5 mb-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 mb-5 mb-md-0">
                                <img src="../assets/landing/images/app_2.png" alt="special offers" className="img-fluid" width="492px" />
                            </div>
                            <div className="col-md-6">
                                <h2 className="section-title">Get special offers on the things you love</h2>
                                <p className="mb-5">He has led a remarkable campaign, defying the traditional mainstream parties courtesy of his En Marche! movement. For many, however, the campaign has become less about backing Macron and instead about voting against Le Pen, the National Front candidate.</p>
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <div className="media landing-feature">
                                            <span className="fa fa-check-circle"></span>
                                            <div className="media-body">
                                                <h5>Essentials</h5>
                                                <p>All the basics for businesses that are just getting started.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className="media landing-feature">
                                        <span className="fa fa-check-circle"></span>
                                            <div className="media-body">
                                                <h5>Premium</h5>
                                                <p>All the basics for businesses that are just getting started.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className="media landing-feature">
                                        <span className="fa fa-check-circle"></span>
                                            <div className="media-body">
                                                <h5>Standard</h5>
                                                <p>All the basics for businesses that are just getting started.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                {/* <section className="py-5 mb-5">
                    <div className="container">
                        <h2>Choose the plan that’s right for yor business</h2>
                        <p className="text-muted mb-5">Thank you for your very professional and prompt response. I wished I had found you before </p>
                        <div className="row">
                            <div className="col-lg-4 mb-4">
                                <div className="card pricing-card border-warning">
                                    <div className="card-body">
                                        <h3 className="mb-1">Starter</h3>
                                        <h3 className="mb-1 text-warning">Free</h3>
                                        <p className="payment-period">Per month</p>
                                        <p className="mb-4">Thank you for your very professional and prompt response.</p>
                                        <button className="btn btn-outline-warning btn-rounded">Get Started</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 mb-4">
                                <div className="card pricing-card border-primary active">
                                    <div className="card-body">
                                        <h3>Popular</h3>
                                        <h3 className="text-primary">$23.00</h3>
                                        <p className="payment-period">Per month</p>
                                        <p className="mb-4">Thank you for your very professional and prompt response.</p>
                                        <button className="btn btn-primary btn-rounded">Get Started</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 mb-4">
                                <div className="card pricing-card border-success">
                                    <div className="card-body">
                                        <h3>Enterprise</h3>
                                        <h3 className="text-success">$40.00</h3>
                                        <p className="payment-period">Per month</p>
                                        <p className="mb-4">Thank you for your very professional and prompt response.</p>
                                        <button className="btn btn-outline-success btn-rounded">Get Started</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                {/* <section className="py-5 mb-5">
                    <div className="container">
                        <h2>Satisfied Users</h2>
                        <p className="text-muted mb-5">Thank you for your very professional and prompt response. I wished I had found you before </p>
                        <div className="row">
                            <div className="col-md-4 foi-review mb-5 mb-md-0">
                                <div className="foi-rating">
                                    <span className="fas fa-star checked"></span>
                                    <span className="fas fa-star checked"></span>
                                    <span className="fas fa-star checked"></span>
                                    <span className="fas fa-star checked"></span>
                                    <span className="fas fa-star checked"></span>
                                </div>
                                <h5 className="foi-review-heading">Great support available</h5>
                                <p className="foi-review-content">Thank you for your very professional and prompt response. I wished I had found you before I spent money on a competitors theme.</p>
                                <div className="media foi-review-user">
                                    <img src="../assets/landing/images/avatar/avatar_11.jpg" alt="user" className="avatar" />
                                        <div className="media-body">
                                            <h6 className="mb-0">Amarachi Nkechi</h6>
                                            <p>UX Designer</p>
                                        </div>
                                </div>

                            </div>
                            <div className="col-md-4 foi-review mb-5 mb-md-0">
                                <div className="foi-rating">
                                    <span className="fas fa-star checked"></span>
                                    <span className="fas fa-star checked"></span>
                                    <span className="fas fa-star checked"></span>
                                    <span className="fas fa-star checked"></span>
                                    <span className="fas fa-star checked"></span>
                                </div>
                                <h5 className="foi-review-heading">Great support available</h5>
                                <p className="foi-review-content">Thank you for your very professional and prompt response. I wished I had found you before I spent money on a competitors theme.</p>
                                <div className="media foi-review-user">
                                    <img src="../assets/landing/images/avatar/avatar_12.jpg" alt="user" className="avatar" />
                                        <div className="media-body">
                                            <h6 className="mb-0">Margje Jutten</h6>
                                            <p>Developer</p>
                                        </div>
                                </div>

                            </div>
                            <div className="col-md-4 foi-review mb-5 mb-md-0">
                                <div className="foi-rating">
                                    <span className="fas fa-star checked"></span>
                                    <span className="fas fa-star checked"></span>
                                    <span className="fas fa-star checked"></span>
                                    <span className="fas fa-star checked"></span>
                                    <span className="fas fa-star checked"></span>
                                </div>
                                <h5 className="foi-review-heading">Great support available</h5>
                                <p className="foi-review-content">Thank you for your very professional and prompt response. I wished I had found you before I spent money on a competitors theme.</p>
                                <div className="media foi-review-user">
                                    <img src="../assets/landing/images/avatar/avatar_13.jpg" alt="user" className="avatar" />
                                        <div className="media-body">
                                            <h6 className="mb-0">Monica Böttger</h6>
                                            <p>UX Designer</p>
                                        </div>
                                </div>

                                </div>
                            </div>
                        </div>
                    </section> */}
                    <section className="py-5 mb-5">
                        <div className="container">
                            <h2>FAQ</h2>
                            <p className="section-subtitle">Frequently Asked Questions</p>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="card mb-3 landing-faq-card">
                                        <div className="card-header bg-white" id="faqOneTitle">
                                            <a href="#faqOneCollapse" className="d-flex align-items-center" data-toggle="collapse">
                                                <h6 className="mb-0">What is BrightMinds app?</h6> <i className="far fa-plus-square ml-auto"></i>
                                            </a>
                                        </div>
                                        <div id="faqOneCollapse" className="collapse" aria-labelledby="faqOneTitle">
                                            <div className="card-body">
                                                <p className="mb-0 text-gray">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card mb-3 landing-faq-card">
                                        <div className="card-header bg-white" id="faqTwoTitle">
                                            <a href="#faqTwoCollapse" className="d-flex align-items-center" data-toggle="collapse">
                                                <h6 className="mb-0">Why should I use BrightMinds app?</h6> <i className="far fa-plus-square ml-auto"></i>
                                            </a>
                                        </div>
                                        <div id="faqTwoCollapse" className="collapse" aria-labelledby="faqTwoTitle">
                                            <div className="card-body">
                                                <p className="mb-0 text-gray">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card mb-3 landing-faq-card">
                                        <div className="card-header bg-white" id="faqThreeTitle">
                                            <a href="#faqThreeCollapse" className="d-flex align-items-center" data-toggle="collapse">
                                                <h6 className="mb-0">How do I download the BrightMinds App?</h6> <i className="far fa-plus-square ml-auto"></i>
                                            </a>
                                        </div>
                                        <div id="faqThreeCollapse" className="collapse" aria-labelledby="faqThreeTitle">
                                            <div className="card-body">
                                                <p className="mb-0 text-gray">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="card mb-3 landing-faq-card">
                                        <div className="card-header bg-white" id="faqFourTitle">
                                            <a href="#faqFourCollapse" className="d-flex align-items-center" data-toggle="collapse">
                                                <h6 className="mb-0">Can I change my membership plan?</h6> <i className="far fa-plus-square ml-auto"></i>
                                            </a>
                                        </div>
                                        <div id="faqFourCollapse" className="collapse" aria-labelledby="faqFourTitle">
                                            <div className="card-body">
                                                <p className="mb-0 text-gray">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card mb-3 landing-faq-card">
                                        <div className="card-header bg-white" id="faqFiveTitle">
                                            <a href="#faqFiveCollapse" className="d-flex align-items-center" data-toggle="collapse">
                                                <h6 className="mb-0">Can I update my profile?</h6> <i className="far fa-plus-square ml-auto"></i>
                                            </a>
                                        </div>
                                        <div id="faqFiveCollapse" className="collapse" aria-labelledby="faqFiveTitle">
                                            <div className="card-body">
                                                <p className="mb-0 text-gray">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card mb-3 landing-faq-card">
                                        <div className="card-header bg-white" id="faqSixTitle">
                                            <a href="#faqSixCollapse" className="d-flex align-items-center" data-toggle="collapse">
                                                <h6 className="mb-0">What is the Indomie Fan Club (IFC)?</h6> <i className="far fa-plus-square ml-auto"></i>
                                            </a>
                                        </div>
                                        <div id="faqSixCollapse" className="collapse" aria-labelledby="faqSixTitle">
                                            <div className="card-body">
                                                <p className="mb-0 text-gray">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <footer className="foi-footer text-white">
                        <div className="container">
                            <div className="row footer-content">
                                <div className="col-xl-6 col-lg-7 col-md-8">
                                    <h2 className="mb-0">Download the Bright Minds Mobile app today to start enjoying</h2>
                                </div>
                                <div className="col-md-4 col-lg-5 col-xl-6 py-3 py-md-0 d-md-flex align-items-center justify-content-end">
                                    {/* <a href="contact.html" className="btn btn-danger btn-lg">Contact form</a> */}
                                <button className="btn btn-app-download mr-2"><img src={require("../assets/landing/images/ios.svg").default} alt="App store" /></button>
                                <button className="btn btn-app-download"><img src={require("../assets/landing/images/android.svg").default} alt="play store" /></button>
                                </div>
                            </div>
                            <div>
                                &copy; {config.app_name} {new Date().getFullYear()} | All rights reserved.
                            </div>
                            {/* <div className="row footer-widget-area">
                                <div className="col-md-3">
                                    <div className="py-3">
                                        <img src={require("../assets/landing/images/logo.png").default} alt="FOI" width="50"/>
                                    </div>
                                    <p className="font-os font-weight-semibold mb3">Get our mobile app</p>
                                    <div>
                                        <button className="btn btn-app-download mr-2"><img src={require("../assets/landing/images/ios.svg").default} alt="App store" /></button>
                                        <button className="btn btn-app-download"><img src={require("../assets/landing/images/android.svg").default} alt="play store" /></button>
                                    </div>
                                </div>
                                <div className="col-md-3 mt-3 mt-md-0">
                                    <nav>
                                        <ul className="nav flex-column">
                                            <li className="nav-item">
                                                <a href="#!" className="nav-link">Account</a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="#!" className="nav-link">My tasks</a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="#!" className="nav-link">Projects</a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="#!" className="nav-link">Edit profile</a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="#!" className="nav-link">Activity</a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                                <div className="col-md-3 mt-3 mt-md-0">
                                    <nav>
                                        <ul className="nav flex-column">
                                            <li className="nav-item">
                                                <a href="#!" className="nav-link">About</a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="#!" className="nav-link">Services</a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="#!" className="nav-link">Careers <span className="badge badge-pill badge-secondary ml-3">Hiring</span></a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="#!" className="nav-link">Blog</a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="#!" className="nav-link">Shop with us</a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                                <div className="col-md-3 mt-3 mt-md-0">
                                    <p>
                                        &copy; {config.app_name} {new Date().getFullYear()}.
                                    </p>
                                    <p>All rights reserved.</p>
                                    <nav className="social-menu">
                                        <a href="#!"><img src={require("../assets/landing/images/facebook.svg").default} alt="facebook"/></a>
                                        <a href="#!"><img src={require("../assets/landing/images/instagram.svg").default} alt="instagram"/></a>
                                        <a href="#!"><img src={require("../assets/landing/images/twitter.svg").default} alt="twitter" /></a>
                                        <a href="#!"><img src={require("../assets/landing/images/youtube.svg").default} alt="youtube" /></a>
                                    </nav>
                                </div>
                            </div> */}
                        </div>
                    </footer>
            </div>
        );
    }
}

export default Home;