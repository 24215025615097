import { Component } from "react";
import { toast } from "react-toastify";
import httpClient from "../../../httpClient";
import AdminLayout from "../../layouts/admin";
import Pagination from "react-pagination-js";
export default class ListSubscriptions extends Component{

    state = {
        subscriptions: [],
        plans: [],
        plan_id: "",
        currentPage: 1,
        pagination: null,
        error: null
    }

    handleInputChange = (e) => {
        const target = e.target;
        let name = target.name;
        let value = null;
        if (target.type === 'checkbox') {
            value = target.checked;
        } else if (target.type === 'file') {
            value = target.files[0];
        } else {
            value = target.value;
        }
        this.setState({
            [name]: value
        });
    }

    fetchSubscription = async (currentPage, e) => {
        this.setState({currentPage})
        let getData = {page: currentPage, planId: this.state.plan_id}
        let response = await httpClient.get('subscriptions/get-subscriptions', {params: getData})
        try {
            let subscriptions = response.data.data.data;
            this.setState({subscriptions});
            this.setState({pagination: response.data.data});
        } catch (error) {
            if (error.response != undefined && error.response.data.message != null) {
                this.setState({ error: error.response.data.message })
            } else {
                this.setState({ error: "There was an error fetching subscriptions" });
            }
        }
        
    }
    fetchPlans = (e) => {

        httpClient.get('plans/active').then(res => {
            const plans = res.data.data;
            this.setState({ plans })
        }).catch(err => {
            console.log("There was an error fetching plans", err);
        })
    }

    componentDidMount(){
        this.fetchSubscription();
        this.fetchPlans();
    }

    render(){
        if (this.state.error != null) {
            return (
                <AdminLayout page_title={`Subscriptions (${this.state.pagination?.total ?? 0})`}>
                    <div className="text-center">
                        {this.state.error} <br />
                        <button className="btn btn-link" onClick={() => { window.location.reload(true) }}>Reload page</button>
                    </div>
                </AdminLayout>
            )
        }
        return (
            <div>
                <AdminLayout page_title={`Subscriptions (${this.state.pagination?.total ?? 0})`}>
                    <div className="form-row">
                        <div className="col col-auto">
                            <select className="form-control" name="plan_id" onChange={this.handleInputChange}>
                                <option value="">Filter by plan</option>
                                {this.state.plans.map((plan) => {
                                    return <option value={plan.id}>{plan.plan_name}</option>
                                })}
                            </select>
                        </div>
                        <div className="col col-auto">
                            <button className="btn btn-primary" type="submit" onClick={(e) => this.fetchSubscription(this.state.currentPage, e)}>Filter</button>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>Username</th>
                                    <th>Name</th>
                                    <th>Phone</th>
                                    <th>Plan</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.subscriptions.map(sub => {
                                    return (
                                        <tr key={sub.id}>
                                            <td>{sub.user.username}</td>
                                            <td>{sub.user.firstname} {sub.user.lastname}</td>
                                            <td>{sub.user.phoneNumber}</td>
                                            <td>{sub.plan.plan_name}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    <Pagination
                        currentPage={this.state.currentPage}
                        totalSize={this.state.pagination?.total ?? 0}
                        changeCurrentPage={this.fetchSubscription}
                        sizePerPage={this.state.pagination?.limit}
                        numberOfPagesNextToActivePage={3}
                        theme="bootstrap"/>
                </AdminLayout>
            </div>
        )
    }
}