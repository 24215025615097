import React from "react";
import ReactLoader from "react-loader";
import { Link, Redirect } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Auth from "../../../helpers/auth";
import httpClient from "../../../httpClient";
import AdminLayout from "../../layouts/admin";
import Pagination from "react-pagination-js";
import "react-pagination-js/dist/styles.css";
const states_and_lgas = require("../../../helpers/statesandlgas.json");
export default class ListMembers extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            members: [
                // {id: 1, first_name: "Sodeeq", last_name: "Olamide", phone: "081234839374"},
                // { id: 2, first_name: "Jane", last_name: "Doe", phone: "09082728372" }
            ],
            states_list: states_and_lgas,
            lgas_list: [],
            state: "",
            lga: "",
            search_query: "",
            age: "",
            gender: "",
            dob: "",
            plans: [],
            membersPagination: null,
            loading: false,
            bulk_upload_file: null
        }
    }

    componentDidMount(){
        this.fetchMembers();
        this.fetchPlans()
    }
    handleStateInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        this.setState({ state: value });
        console.log("state", value);
        const selectedState = this.state.states_list.find(item => {
            return item.state.name == value;
        });

        if (selectedState != undefined) {
            this.setState({ lgas_list: selectedState.state.locals });
        } else {
            this.setState({ lgas_list: [] });
        }

    }
    uploadBulkFile = () => {
        this.setState({ loading: true })
        let formData = new FormData();
        formData.append('members', this.state.bulk_upload_file);
        httpClient.post(`users/bulk-upload-members`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data;',
            }
        }).then(res => {
            this.setState({ loading: false });
            toast.success("File updated successfully");
        }).catch(err => {
            this.setState({ loading: false });
            toast.error("There was an error uploading the file")
        })
    }
    handleInputChange = (e) => {
        const target = e.target;
        let name = target.name;
        let value = null;
        if (target.type === 'checkbox') {
            value = target.checked;
        } else if (target.type === 'file') {
            value = target.files[0];
        } else {
            value = target.value;
        }
        this.setState({
            [name]: value
        });
    }

    fetchPlans = (e) => {
        
        httpClient.get('plans/active').then(res => {
            const plans = res.data.data;
            this.setState({ plans })
        }).catch(err => {
            console.log("There was an error fetching plans", err);
        })
    }

    fetchMembers = (currentPage, e) => {
        // console.log(e);
        // if (e !== undefined && e instanceof Object) {
        //     e.preventDefault()
        // }
        this.setState({ currentPage });
        this.setState({loading: true})
        let searchQuery = {
            search: this.state.search_query,
            gender: this.state.gender,
            state: this.state.state,
            lga: this.state.lga,
            dob: this.state.dob,
            page: currentPage,
        }
        // console.log(searchQuery);
        
        httpClient.get('users', {params: searchQuery}).then(res => {
            const members = res.data.data.data;
            this.setState({members})
            this.setState({membersPagination: res.data.data})
        }).catch(err => {
            console.log(err);
            console.log("there was an error fetching members", err);
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    deleteMember = (index, e) => {
        e.preventDefault();
        if (!window.confirm("Are you sure you want to delete this member? This action is irreversible")){
            return;
        }
        let members = this.state.members;
        
        let member = this.state.members[index];
        httpClient.delete(`users/${member.id}`).then(res => {
            toast.info("Member deleted successfully");
            members.splice(index, 1);
            this.setState({members})
        }).catch(err => {
            toast.error("There was an error deleting that member, please try again");
        })
    }

    render(){
        return(
            <div className="">
                <AdminLayout page_title={`Members (${this.state.membersPagination?.total ?? 0})`}>
                    <div className="table-responsive">
                        <div className="p-3">
                            
                            {/* <form onSubmit={this.fetchMembers}> */}
                                <div className="form-row">
                                    <div className="col col-auto">
                                        <input type="search" placeholder="Type to search..." className="form-control" name="search_query" onChange={this.handleInputChange} />
                                    </div>
                                    <div className="col col-auto">
                                        <select className="form-control" name="state" onChange={this.handleStateInputChange}>
                                            <option value="">Filter by state</option>
                                            {states_and_lgas.map((item) => {
                                                return <option value={item.state.name} key={item.state.id}>{item.state.name}</option>
                                            })}
                                        </select>
                                    </div>
                                    {/* <div className="col-auto col">
                                        <select className="form-control" name="lga" onChange={this.handleInputChange}>
                                            <option value="">Filter by LGA</option>
                                            {this.state.lgas_list.map((lga) => {
                                                return <option key={lga.name}>{lga.name}</option>
                                            })}
                                        </select>
                                    </div> */}
                                    <div className="col-auto col">
                                        <select name="gender" id="genders" className="form-control" onChange={this.handleInputChange}>
                                            <option value="">Filter by gender</option>
                                            <option value="female">Female</option>
                                            <option value="male">Male</option>
                                        </select>
                                    </div>
                                    
                                    <div className="col col-auto">
                                        <button className="btn btn-primary" type="submit" onClick={(e) => this.fetchMembers(this.state.currentPage, e)}>Filter</button>
                                    </div>
                                </div>
                            {/* </form> */}
                            
                        </div>
                        
                        <ReactLoader loaded={!this.state.loading}>
                            <table className="table table-striped">
                                <caption>Showing {this.state.members.length} of {this.state.membersPagination?.total ?? 0} members</caption>
                                <tbody>
                                    <tr>
                                        {/* <th>ID</th> */}
                                        <th>Username</th>
                                        <th>First name</th>
                                        <th>Last name</th>
                                        <th>Phone</th>
                                        <th>Gender</th>
                                        <th>Actions</th>
                                    </tr>
                                    {this.state.members.map((member, index) => {
                                        return (<tr key={member.id}>
                                            {/* <td>{member.id}</td> */}
                                            <td>{member.username}</td>
                                            <td>{member.firstname}</td>
                                            <td>{member.lastname}</td>
                                            <td>{member.phoneNumber}</td>
                                            <td>{member.gender != null ? member.gender.charAt(0).toUpperCase() + member.gender.slice(1): ''}</td>
                                            <td>
                                                <div className="dropdown">
                                                    <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <i className="fa fa-cog"></i> Actions
                                                </button>
                                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                        <Link className="dropdown-item" to={`/admin/members/${member.id}/view`}><i className="fa fa-eye"></i> View</Link>
                                                        {/* <a className="dropdown-item" href="#"><i className="fa fa-edit"></i> Edit</a> */}
                                                        <a className="dropdown-item" href="#" onClick={(e) => this.deleteMember(index, e)}><i className="fa fa-times"></i> Deactivate</a>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>)
                                    })}
                                </tbody>
                            </table>
                            <Pagination
                                currentPage={this.state.currentPage}
                                totalSize={this.state.membersPagination?.total ?? 0}
                                changeCurrentPage={this.fetchMembers}
                                sizePerPage={this.state.membersPagination?.limit}
                                numberOfPagesNextToActivePage={3}
                                theme="bootstrap"
                            />
                        </ReactLoader>
                        
                    </div>
                </AdminLayout>
            </div>
        )
    }
}