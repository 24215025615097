import React, {Component} from "react";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import Auth from "../../../helpers/auth";
import httpClient from "../../../httpClient";
import { internationalizePhoneNumber } from "../../../utils";
import AdminLayout from "../../layouts/admin";
const states_and_lgas = require("../../../helpers/statesandlgas.json");
var Loader = require('react-loader');
export default class CreateMember extends Component{
    state = {
        phone_number: "",
        password: "",
        firstname: "",
        lastname: "",
        username: "",
        date_of_birth: "",
        gender: "",
        school_name: "",
        address: "",
        state: "",
        lga: "",
        step: 0,
        states_list: states_and_lgas,
        lgas_list: [],
        validationErrors: [],
        loading: false,
        passType: "password"
    }
    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleStateInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        this.setState({ state: value });
        console.log("state", value);
        const selectedState = this.state.states_list.find(item => {
            return item.state.name == value;
        });

        if (selectedState != undefined) {
            this.setState({ lgas_list: selectedState.state.locals });
        } else {
            this.setState({ lgas_list: [] });
        }

    }
    render(){
        
        return (
            <div>
                <AdminLayout page_title="Add new member">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col col-md-2 d-none d-md-block"></div>
                            <div className="col col-md-8">
                                <div className="card card-body">
                                    {this.state.validationErrors.length > 0 &&
                                        <ul className="list-group">
                                            {this.state.validationErrors.map((error) => {
                                                return (<li className="list-group-item list-group-item-danger">{error}</li>)
                                            })}
                                        </ul>
    
                                    }
                                    <form onSubmit={this.doSignup}>
                                        <div className="form-group">
                                            <input className="form-control" type="text" placeholder="First name" name="firstname" value={this.state.firstname} onChange={this.handleInputChange} />
                                        </div>
                                        <div className="form-group">
                                            <input className="form-control" type="text" placeholder="Last name" name="lastname" value={this.state.lastname} onChange={this.handleInputChange} />
                                        </div>
                                        <div className="form-group">
                                            <input className="form-control" type="text" placeholder="Username" name="username" value={this.state.username} onChange={this.handleInputChange} />
                                        </div>
                                        <div className="form-group" style={{ position:"relative" }}>
                                            <input className="form-control" type={this.state.passType} placeholder="Password" name="password" onChange={this.handleInputChange}/>
                                            <a href="#" style={{ position: "absolute", right: "5%", top: "20%" }} onClick={e => {
                                                e.preventDefault();
                                                console.log(this.state.passType);
                                                if (this.state.passType == 'password') {
                                                    this.setState({ passType: 'text' });
                                                } else {
                                                    this.setState({ passType: 'password' });
                                                }
                                            }}><i className={'fa fa-' + (this.state.passType == 'password' ? 'eye' : 'eye-slash')}></i></a>
                                        </div>
                                        <div className="form-group">
                                            <input className="form-control" name="phone_number" type="tel" placeholder="Phone number" value={this.state.phoneNumber} onChange={this.handleInputChange} />
                                        </div>
                                        <div className="form-group">
                                            <input className="form-control" type="date" placeholder="Date of birth" name="date_of_birth" value={this.state.date_of_birth} onChange={this.handleInputChange} />
                                        </div>
                                        <div className="form-group">
                                            <select className="form-control" name="gender" value={this.state.gender} onChange={this.handleInputChange}>
                                                <option value="">Gender</option>
                                                <option value="female">Female</option>
                                                <option value="male">Male</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <input className="form-control" placeholder="Name of school" name="school_name" value={this.state.school_name} onChange={this.handleInputChange} />
                                        </div>
                                        <div className="form-group">
                                            <input className="form-control" placeholder="Home address" name="address" value={this.state.address.street} onChange={this.handleInputChange} />
                                        </div>
                                        <div className="form-group">
                                            <select className="form-control" value={this.state.state} name="state" onChange={this.handleStateInputChange}>
                                                <option value="">Select state</option>
                                                {states_and_lgas.map((item) => {
                                                    return <option value={item.state.name} key={item.state.id}>{item.state.name}</option>
                                                })}
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <select className="form-control" name="lga" onChange={this.handleInputChange}>
    
                                                {this.state.lgas_list.map((lga) => {
                                                    return <option>{lga.name}</option>
                                                })}
                                            </select>
                                        </div>
                                        <div className="form-group"></div>
                                        <div className="text-center mb-4">
                                            <button className="btn btn-primary" type="submit">Create Member</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </AdminLayout>
            </div>
        )
    }

    doSignup = (e) => {
        this.setState({ validationErrors: [], loading: true })
        e.preventDefault();

        const signupData = {
            phoneNumber: internationalizePhoneNumber(this.state.phone_number),
            password: this.state.password,
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            username: this.state.username,
            dob: this.state.date_of_birth,
            gender: this.state.gender,
            address: {
                street: this.state.address,
                state: this.state.state,
                lga: this.state.lga,
            },
            school: this.state.school_name
        }

        httpClient.post("admin/members", signupData).then(res => {
            toast.success("Member account has been created successfully.");
            const resData = res.data.data;
            
            const user = resData.user;
            const {history} = this.props;
            history.push("/admin/members");
            
        }).catch(err => {
            toast.error("There was a problem creating member account");
            console.log(err.response)

            if (err.response && err.response.status == 400) {

                let formErrors = err.response.data.message;
                if (Array.isArray(formErrors) || (typeof formErrors === 'object' && formErrors !== null)) {
                    formErrors = Object.values(formErrors).flat();
                    this.setState({ validationErrors: formErrors })
                } else {
                    formErrors = formErrors.split(",");
                    this.setState({ validationErrors: formErrors })
                }
            } else {
                if (err.response && err.response.data.message != undefined) {
                    const errorMessage = [err.response.data.message];
                    this.setState({ validationErrors: errorMessage });
                }
            }
        }).finally(response => {
            this.setState({ loading: false });
        })
    }
}