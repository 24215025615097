import React from "react";
import "../../assets/css/css-import-util.css";
import httpClient from "../../httpClient";
import { Link, useLocation } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Auth from "../../helpers/auth";
import { internationalizePhoneNumber } from "../../utils";
var Loader = require('react-loader');

export default class AdminSignup extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            identifier: "",
            password: "",
            firstname: "",
            lastname: "",
            username: "",
            email: "",
            validationErrors: [],
            loading: false,
            passType: "password"
        }
        this.doSignup = this.doSignup.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount(){
        
    }
    render(){
        require("../../assets/css/login.css")
        return (
            <div className="adminLogin">
                <div className="limiter">
                    <div className="container-login100">
                        <div className="wrap-login100 p-l-55 p-r-55 p-t-45 p-b-50">
                            <Link to="/">
                                <img src={process.env.PUBLIC_URL + '/logo.png'} width="70" />
                            </Link>
                            <form className="login100-form validate-form" onSubmit={this.doSignup}>
                                <span className="login100-form-title p-b-33">
                                    Create Account - Admin
                                </span>
                                {this.state.validationErrors.length > 0 && 
                                    <ul className="list-group">
                                    {this.state.validationErrors.map((error) => {
                                        return (<li className="list-group-item list-group-item-danger">{error}</li>)
                                    })}
                                    </ul>

                                }
                                <div className="registration-step step-1">
                                    <div className="wrap-input100 validate-input" data-validate="Valid email is required: ex@abc.xyz">
                                        <input className="input100" type="tel" name="identifier" placeholder="Phone number" value={this.state.identifier} onChange={this.handleInputChange} />
                                        <label htmlFor="phone number" className="control-label">Phone number</label>
                                        <span className="focus-input100-1"></span>
                                        <span className="focus-input100-2"></span>
                                    </div>
                                    <div className="wrap-input100 validate-input" data-validate="First name is required">
                                        <input className="input100" type="text" name="firstname" placeholder="First name" value={this.state.firstname} onChange={this.handleInputChange}/>
                                        <label className="control-label" htmlFor="firstname">First name</label>
                                        <span className="focus-input100-1"></span>
                                        <span className="focus-input100-2"></span>
                                    </div>
                                    <div className="wrap-input100 validate-input" data-validate="First name is required">
                                        <input className="input100" type="text" name="lastname" placeholder="Last name" value={this.state.lastname} onChange={this.handleInputChange} />
                                        <label className="control-label" htmlFor="lastname">Last name</label>
                                        <span className="focus-input100-1"></span>
                                        <span className="focus-input100-2"></span>
                                    </div>
                                    <div className="wrap-input100 validate-input" data-validate="Username is required and should be unique">
                                        <input className="input100" type="text" name="username" placeholder="username" value={this.state.username} onChange={this.handleInputChange}/>
                                        <label htmlFor="username" className="control-label">Username</label>
                                        <span className="focus-input100-1"></span>
                                        <span className="focus-input100-2"></span>
                                    </div>
                                    <div className="wrap-input100 validate-input" data-validate="Email is required and should be unique">
                                        <input className="input100" type="email" name="email" placeholder="email address" value={this.state.email} onChange={this.handleInputChange} />
                                        <label htmlFor="username" className="control-label">Email</label>
                                        <span className="focus-input100-1"></span>
                                        <span className="focus-input100-2"></span>
                                    </div>
                                    <div className="wrap-input100 rs1 validate-input" data-validate="Password is required">
                                        <a href="#" style={{ position:"absolute", right: "5%", top: "30%" }} onClick={e => {
                                            e.preventDefault();
                                            if (this.state.passType == 'password'){
                                                this.setState({passType: 'text'});
                                            }else{
                                                this.setState({passType: 'password'});
                                            }
                                        }}><i className={'fa fa-' + (this.state.passType == 'password' ? 'eye' : 'eye-slash')}></i></a>
                                        <input className="input100" type={this.state.passType} name="password" placeholder="Password" value={this.state.password} onChange={this.handleInputChange} />
                                        <label className="control-label" htmlFor="password">Password</label>
                                        <span className="focus-input100-1"></span>
                                        <span className="focus-input100-2"></span>
                                    </div>
                                </div>
                                <Loader loaded={!this.state.loading}>
                                <div className="container-login100-form-btn m-t-20">
                                    
                                    <button className="login100-form-btn" type="submit">
                                        Register
                                    </button>
                                    
                                </div>
                                </Loader>
                                
                                <div className="text-center">
                                    <span className="txt1">
                                        Already have an account? &nbsp;
                                    </span>
                                    <Link to="/admin/login" className="txt2 hov1">
                                        Sign in
                                    </Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <ToastContainer/>  
            </div>
        );
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    doSignup(e){
        this.setState({validationErrors: [], loading: true})
        e.preventDefault();
        
        const signupData = {
            phoneNumber: internationalizePhoneNumber(this.state.identifier),
            password: this.state.password,
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            username: this.state.username,
            email: this.state.email
        }
        
        httpClient.post("auth/admin/signup", signupData).then(res => {
            toast.success("Your account has been created successfully. Complete your profile by uploading a profile picture and choosing a membership plan");
            console.log(res.data.data);
            const resData = res.data.data;
            const token = resData.token;
            const user = resData.user;
            Auth.saveToken(token);
            Auth.saveUser(user);
            const { history } = this.props;
            // history.push("/dashboard");
            // window.location.href = "/admin/dashboard";
        }).catch(err => {
            toast.error("There was a problem creating your account");
            console.log(err.response)
            
            if (err.response && err.response.status == 400){

                let formErrors = err.response.data.message;
                if (Array.isArray(formErrors) || (typeof formErrors === 'object' && formErrors !== null)){
                    formErrors = Object.values(formErrors).flat();
                    this.setState({validationErrors: formErrors})
                }else{
                    formErrors = formErrors.split(",");
                    this.setState({ validationErrors: formErrors })
                }
            }else{
                if (err.response && err.response.data.message != undefined) {
                    const errorMessage = [err.response.data.message];
                    this.setState({ validationErrors: errorMessage });
                }
            }
        }).finally(response => {
            this.setState({loading: false});
        })
    }
}
