import React from "react";
import "../../assets/css/css-import-util.css";

import { Link, Redirect, useHistory, useLocation } from "react-router-dom";

class About extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        
    }
    componentDidMount() {

    }
    render() {
        
        require("../../assets/css/loginstyle.css")
        return (
            <div className="container-fluid">
                <div className="bodyWrapper">
                    <div className="row">
                        <div className="col">
                            <div className="text-center">
                                <Link to="/">
                                    <img src={process.env.PUBLIC_URL + '/logo.png'} className="bodyLogo" />
                                </Link>
                            </div>
                            <h2>About Us</h2>
                        </div>
                    </div>
                    
                </div>

            </div>
        );
    }
}

export default About;

