import { Component } from "react";
import Logout from "../auth/logout";
import { config } from "./../../appconfig";
export default class Sidebar extends Component{
    render(){
        return (
            <div className="sidebar" data-image="../assets/img/sidebar-5.jpg">

                <div className="sidebar-wrapper">
                    <div className="logo">
                        <a href="/admin" className="simple-text">
                            {config.app_name}
                        </a>
                    </div>
                    <ul className="nav">
                        <li className="nav-item">
                            <a className="nav-link" href="/admin/dashboard">
                                <i className="fa fa-tachometer-alt"></i>
                                <p>Dashboard</p>
                            </a>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fa fa-users"></i>
                                <p>Members</p>
                            </a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                <a className="dropdown-item" href="/admin/members/create">Add member</a>
                                <a className="dropdown-item" href="/admin/members">List members</a>
                                <a href="/admin/members/bulk-create" className="dropdown-item">Bulk upload</a>
                            </div>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fa fa-box"></i>
                                <p>Membership plans</p>
                            </a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                <a className="dropdown-item" href="/admin/plans/create">Add plan</a>
                                <a className="dropdown-item" href="/admin/plans">List plans</a>

                            </div>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fa fa-calendar-day"></i>
                                <p>Events</p>
                            </a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                <a className="dropdown-item" href="/admin/events/create">Add event</a>
                                <a className="dropdown-item" href="/admin/events">List events</a>

                            </div>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fa fa-ticket-alt"></i>
                                <p>Competitions</p>
                            </a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                <a className="dropdown-item" href="/admin/competitions/create">Add competition</a>
                                <a className="dropdown-item" href="/admin/competitions">List competitions</a>

                            </div>
                        </li>
                        
                        <li>
                            <a className="nav-link" href="/admin/subscriptions">
                                <i className="fa fa-money-bill"></i>
                                <p>Subscriptions</p>
                            </a>
                        </li>
                        <li>
                            <a className="nav-link" href="/admin/stickers">
                                <i className="fa fa-sticky-note"></i>
                                <p>Manage stickers</p>
                            </a>
                        </li>
                        <li>
                            <a href="/admin/announcements" className="nav-link">
                                <i className="fa fa-bullhorn"></i>
                                <p>Announcements</p>
                            </a>
                        </li>
                        {/* <li>
                        <a className="nav-link" href="./typography.html">
                            <i className="nc-icon nc-paper-2"></i>
                            <p>Typography</p>
                        </a>
                    </li>
                    <li>
                        <a className="nav-link" href="./icons.html">
                            <i className="nc-icon nc-atom"></i>
                            <p>Icons</p>
                        </a>
                    </li>
                    <li>
                        <a className="nav-link" href="./maps.html">
                            <i className="nc-icon nc-pin-3"></i>
                            <p>Maps</p>
                        </a>
                    </li>
                    <li>
                        <a className="nav-link" href="./notifications.html">
                            <i className="nc-icon nc-bell-55"></i>
                            <p>Notifications</p>
                        </a>
                    </li> */}
                        <li className="nav-item">
                            <Logout classes="nav-link" redirectUrl="/admin/login">
                                <i className="fa fa-sign-out-alt"></i>
                                Logout
                            </Logout>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
    
}