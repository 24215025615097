import React, { Component } from "react";
import { loadScripts } from "../../utils";
import Sidebar from "./sidebar";
import Footer from "./footer";
import Navbar from "./navbar";

import { ToastContainer } from "react-toastify";
import "../../assets/js/light-bootstrap-dashboard.js";
import Auth from "../../helpers/auth";
import { Redirect } from "react-router-dom";
export default class AdminLayout extends Component{
    render(){
        require("../../assets/css/light-bootstrap-dashboard.css");
        if (!Auth.isLoggedIn() || !Auth.isAdmin()) {
            Auth.logout();
            return <Redirect to="/admin/login"></Redirect>
        }
        
        return (
            <div>
                <div className="wrapper">
                    <Sidebar/>
                    <div className="main-panel">
                        <Navbar page_title={this.props.page_title} />
                        <div className="content">
                            <div className="container-fluid">
                                {this.props.children}
                            </div>
                        </div>
                        
                    </div>
                    <Footer/>
                    <ToastContainer/>
                </div>
            </div>
        );
    }
    componentDidMount(){
    }
}