import React from "react";
import httpClient from "../../../httpClient";
import { formatMoney } from "../../../utils";
import AdminLayout from "../../layouts/admin";
import $ from "jquery";
import { toast } from "react-toastify";

var Loader = require("react-loader");
export default class ListPlans extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            plans: [
                // { id: 1, name: "Gold", price: 2000, description: "A lengthy description" },
                // { id: 2, name: "Platinum", price: 25000, description: "A lengthy description for this too"}
            ],
            plan_to_edit: null,
            new_plan_name: "",
            new_plan_price: "",
            new_plan_description: "",
            loading: false
        }
    }
    componentDidMount(){
        httpClient.get('plans').then(res => {
            const plans = res.data.data.data;
            this.setState({ plans })
        }).catch(err => {
            console.log("There was an error fetching plans", err);
        })
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    togglePlanActiveStatus = (plan) => {
        let newStatus = "true";
        if (plan.is_active == true){
            newStatus = "false";
        }else{
            newStatus = "true";
        }
        
        httpClient.put("/plans/"+plan.id, {is_active: newStatus}).then(res => {
            toast.info("Plan status updated");
            window.location.reload();
        }).catch(err => {
            console.log(err);
        })
    }

    openEditModal = (plan_to_edit, e) => {
        e.preventDefault();
        this.setState({plan_to_edit});
        this.setState({
            new_plan_name: plan_to_edit.plan_name,
            new_plan_price: plan_to_edit.price,
            new_plan_description: plan_to_edit.description
        })
        $("#editModal").modal("show");
    }

    updatePlan = (e) => {
        e.preventDefault();
        this.setState({loading: true});
        let plan = {
            plan_name: this.state.new_plan_name,
            description: this.state.new_plan_description,
            price: this.state.new_plan_price + ""
        }
        httpClient.put("plans/"+this.state.plan_to_edit.id, plan).then(res => {
            window.location.reload();
        }).catch(err => {
            toast.error("There was an error updating the plan details, please try again");
        }).finally(() => {
            this.setState({loading: false});
        })
    }
    render() {
        return (
            <div className="">
                <AdminLayout page_title="Membership Plans">
                    <div className="table-responsive">
                        <table className="table table-striped">
                            <tbody>
                                <tr>
                                    {/* <th>ID</th> */}
                                    <th>Plan</th>
                                    <th>Price (NGN)</th>
                                    <th>Description</th>
                                    <th>Status</th>
                                    <th>Actions</th>
                                </tr>
                                {this.state.plans.map((plan) => {
                                    return (<tr key={plan.id}>
                                        {/* <td>{plan.id}</td> */}
                                        <td>{plan.plan_name}</td>
                                        <td>{formatMoney(plan.price)}</td>
                                        <td>{plan.description}</td>
                                        <td>{plan.is_active ? <span className="badge badge-success badge-pill">Active</span> : <span className="badge badge-pill badge-warning">Not active</span> }</td>
                                        <td>
                                            <div className="dropdown">
                                                <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <i className="fa fa-cog"></i> Actions
                                                </button>
                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    {/* <a className="dropdown-item" href="#"><i className="fa fa-eye"></i> View</a> */}
                                                    <a className="dropdown-item" href="#" onClick={(e) => this.openEditModal(plan, e)}><i className="fa fa-edit"></i> Edit</a>
                                                    <a className="dropdown-item" href="#" onClick={(e) => this.togglePlanActiveStatus(plan, e)}>{plan.is_active == true ? <span><i className="fa fa-times"></i> Deactivate</span> : <span><i className="fa fa-check"></i> Activate</span>}</a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>)


                                })}
                            </tbody>
                        </table>
                    </div>
                </AdminLayout>
                <div className="modal fade" id="editModal" tabIndex="-1" aria-labelledby="editPlan" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Update plan details</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form onSubmit={this.updatePlan}>
                                    <div className="form-row">
                                        <div className="col form-group">
                                            <label htmlFor="title">Membership title</label>
                                            <input className="form-control" name="new_plan_name" value={this.state.new_plan_name} placeholder="Membership name e.g Gold" onChange={this.handleInputChange} required />
                                        </div>
                                        <div className="col form-group">
                                            <label htmlFor="price">Price of plan (NGN)</label>
                                            <input className="form-control" type="number" step="any" name="new_plan_price" value={this.state.new_plan_price} onChange={this.handleInputChange} required />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col form-group">
                                            <label htmlFor="Plan description">Plan description</label>
                                            <textarea className="form-control" placeholder="Description/features of the plan" name="new_plan_description" value={this.state.new_plan_description} onChange={this.handleInputChange} required></textarea>
                                        </div>
                                    </div>
                                    <div>
                                        <Loader loaded={!this.state.loading}>
                                            <button type="submit" className="btn btn-primary">Update plan</button>
                                        </Loader>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}