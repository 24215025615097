import dayjs from "dayjs";
import { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import httpClient from "../../../httpClient";
import AdminLayout from "../../layouts/admin";
import Pagination from "react-pagination-js";
import "react-pagination-js/dist/styles.css";


export default class ListCompetitions extends Component{
    state = {
        competitions: [],
        pagination: null,
        currentPage: 1,
        error: null
    }

    getCompetitions = (currentPage = 1) => {
        this.setState({ currentPage });
        let getData = {
            page: currentPage,
        }
        httpClient.get("competitions", {params: getData}).then(res => {
            let competitions = res.data.data.data;
            this.setState({competitions})
            this.setState({ pagination: res.data.data })
        }).catch(error => {
            if (error.response != undefined && error.response.data.message != null) {
                this.setState({ error: error.response.data.message })
            } else {
                this.setState({ error: "There was an error fetching competitions" });
            }
        })
    }

    deleteCompetition = (index, e) => {
        e.preventDefault();
        if (!window.confirm("Are you sure you want to delete this competition? This action is irreversible")) {
            return;
        }
        let competitions = this.state.competitions;

        let competition = this.state.competitions[index];
        httpClient.delete(`competitions/${competition.id}`).then(res => {
            toast.info("Competition deleted successfully");
            competitions.splice(index, 1);
            this.setState({ competitions })
        }).catch(err => {
            toast.error("There was an error deleting that competition, please try again");
        })
    }

    componentDidMount(){
        this.getCompetitions();
    }

    render(){
        if (this.state.error != null) {
            return (
                <AdminLayout page_title={`Competitions (${this.state.pagination?.total ?? 0})`}>
                    <div>
                        <Link to="/admin/competitions/create" className="btn btn-primary float-right">Add new</Link>
                    </div>
                    <div className="text-center">
                        {this.state.error} <br />
                        <button className="btn btn-link" onClick={() => { window.location.reload(true) }}>Reload page</button>
                    </div>
                </AdminLayout>
            )
        }
        if (this.state.competitions.length < 1) {
            return (
                <AdminLayout page_title={`Competitions (${this.state.pagination?.total ?? 0})`}>
                    <div className="text-center">
                        No competitions have been created yet. <br />
                        <Link to="/admin/competitions/create">Create one</Link>
                    </div>
                </AdminLayout>
            )
        }
        
        return (
            <div>
                <AdminLayout page_title={`Competitions (${this.state.pagination?.total ?? 0})`}>
                    <div className="table-responsive">
                        <div>
                            <Link to="/admin/competitions/create" className="btn btn-primary float-right">Add new</Link>
                        </div>
                        <table className="table table-striped">
                            <thead className="thead-dark">
                                <tr>
                                    <th scope="col">Title</th>
                                    <th scope="col">Opening date</th>
                                    <th scope="col">Closing date</th>
                                    <th scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.competitions.map((competition, index) => {
                                    return (<tr key={competition.id}>
                                        <td>{competition.title}</td>
                                        <td>{dayjs(competition.opening_date).format("YYYY-MM-DD HH:mm")}</td>
                                        <td>{dayjs(competition.closing_date).format("YYYY-MM-DD HH:mm")}</td>
                                        <td>
                                            <div className="dropdown">
                                                <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <i className="fa fa-cog"></i> Actions
                                                </button>
                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    <Link className="dropdown-item" to={`/admin/competitions/${competition.id}/view`}><i className="fa fa-eye"></i> View</Link>
                                                    <Link className="dropdown-item" to={`/admin/competitions/${competition.id}/edit`}><i className="fa fa-edit"></i> Edit</Link>
                                                    <a className="dropdown-item" href="#" onClick={(e) => this.deleteCompetition(index, e)}><i className="fa fa-times"></i> Delete</a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>)
                                })}
                            </tbody>
                        </table>
                        <Pagination 
                            currentPage={this.state.currentPage}
                            totalSize={this.state.pagination?.total ?? 0}
                            changeCurrentPage={this.getCompetitions}
                            sizePerPage={this.state.pagination?.limit}
                            numberOfPagesNextToActivePage={3}
                            theme="bootstrap"
                        />
                    </div>
                </AdminLayout>
            </div>
        )
    }
}