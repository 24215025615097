import { Component } from "react";
import { toast } from "react-toastify";
import httpClient from "../../../httpClient";
import AdminLayout from "../../layouts/admin";
import $ from "jquery";
import ReactLoader from "react-loader";

var Loader = require("react-loader");
export default class ViewStickers extends Component{
    state = {
        stickers: [],
        tags: "",
        stickerFile: "",
        stickerToEdit: null,
        loading: false
    }
    componentDidMount(){
        this.getStickers();
    }
    getStickers(){
        this.setState({loading: true})
        httpClient.get("stickers").then(res => {
            this.setState({stickers: res.data.data});
        }).catch(err => {
            console.log(err)
        }).finally(() => {
            this.setState({ loading: false })
        })
    }
    addNewSticker = async (e) => {
        e.preventDefault();
        this.setState({ loading: true });
        let tags = this.state.tags;
        
        let formData = new FormData();
        formData.append("image", this.state.stickerFile);
        formData.append("tags", tags);
        httpClient.post("/stickers", formData, {
            headers: {
                'Content-Type': 'multipart/form-data;',
            }
        }).then(res => {
            toast.success("Sticker uploaded successfully");
            let sticker = res.data.data;
            let stickers = this.state.stickers;
            stickers.push(sticker);
            this.setState({ stickers });
            this.stickerFile.value = "";
            this.tags.value = "";
        }).catch(err => {
            console.log(err.response);
        }).finally(() => {
            this.setState({ loading: false });
        })
    }

    openEditModal = (index, e) => {
        let sticker = this.state.stickers[index];
        this.setState({stickerToEdit: sticker});
        this.setState({tags: sticker.tags});
        $("#editModal").modal("show");
    }
    editSticker = (e) => {
        e.preventDefault();
        let sticker = this.state.stickerToEdit;
        httpClient.put(`stickers/${sticker.id}`, {tags: this.state.tags}).then(res => {
            $("#editModal").modal("hide");
            setTimeout(() => {
                window.location.reload()
            }, 1000);
        }).catch(err => {
            toast.error("There was an error updating the sticker, please try later");
        });
    }

    deleteSticker = async (index) => {
        if (!window.confirm("Are you sure you want to delete this sticker?")){
            return false;
        }
        let sticker = this.state.stickers[index];
        let stickers = this.state.stickers;
        httpClient.delete(`stickers/${sticker.id}`).then(res => {
            stickers.splice(index, 1);
            this.setState({stickers});
        }).catch(err => {
            toast.error("There was a problem deleting the sticker, please try later");
        });
    }
    handleInputChange = (e) => {
        const target = e.target;
        let name = target.name;
        let value = null;

        if (target.type === 'checkbox') {
            value = target.checked;
        } else if (target.type === 'file') {
            value = target.files[0];
        } else if (target.multiple === true) {
            let options = Array.from(e.target.selectedOptions, option => option.value);
            value = options.filter(function (el) {
                return el != null && el != "" && el != undefined;
            });
        }
        else {
            value = target.value;
        }
        this.setState({
            [name]: value
        });
    }
    render(){
        return(
            <div>
                <AdminLayout page_title="Manage stickers">
                    <div className="row">
                        <div className="col">
                            <ReactLoader loaded={!this.state.loading}>
                            <div className="table-responsive">
                                <div className="">
                                    <div className="float-right">
                                        <button className="btn btn-secondary btn-sm" data-toggle="modal" data-target="#addNewModal">Add new</button>
                                    </div>
                                </div>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Sticker</th>
                                            <th>Tags</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.stickers.map((sticker, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td><img src={sticker.url} alt={sticker.tags} className="img-fluid" style={{ width: 80, height:80 }}/></td>
                                                    <td>{ sticker.tags }</td>
                                                    <td>
                                                        <button className="btn btn-info btn-sm" onClick={(e) => this.openEditModal(index, e)}>Edit</button>
                                                        <button className="btn btn-danger btn-sm" onClick={(e) => this.deleteSticker(index)}>Delete</button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            </ReactLoader>
                        </div>
                    </div>
                </AdminLayout>
                <div className="modal fade" id="addNewModal">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Add new sticker</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form action="" onSubmit={this.addNewSticker}>
                                    <div className="form-group">
                                        <label htmlFor="Image">Upload sticker</label>
                                        <input type="file" name="stickerFile" id="stickerFile" className="form-control" accept="image/*" onChange={this.handleInputChange} required ref={(el) => (this.stickerFile = el)}/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="Description tags">Description tags</label>
                                        <input type="text" name="tags" id="tags" className="form-control" placeholder="Enter tags (separate multiple with comma)" onChange={this.handleInputChange} required ref={(el) => (this.tags = el)}/>
                                    </div>
                                    <div>
                                        <Loader loaded={!this.state.loading}>
                                            <button className="btn btn-primary" type="submit">Upload</button>
                                        </Loader>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="editModal">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Update sticker</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form action="" onSubmit={this.editSticker}>
                                    <div className="text-center">
                                        <img src={this.state.stickerToEdit?.url} className="img-fluid" width="80"/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="Description tags">Description tags</label>
                                        <input type="text" name="tags" id="tags" className="form-control" placeholder="Enter tags (separate multiple with comma)" onChange={this.handleInputChange} required defaultValue={this.state.stickerToEdit?.tags}/>
                                    </div>
                                    <div>
                                        <button className="btn btn-primary" type="submit">Update</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}