import dayjs from "dayjs";
import { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import httpClient from "../../../httpClient";
import AdminLayout from "../../layouts/admin";

export default class ShowEvent extends Component{
    eventId = "";
    constructor(props){
        super(props);
        this.eventId = this.props.match.params.id;
    }

    state = {
        event: {},
        attendees: [],
        pagination: null
    }

    fetchEvent = () => {
        httpClient.get(`events/${this.eventId}`).then(res => {
            let event = res.data.data;
            this.setState({event});
        })
    }

    fetchAttendees = () => {
        httpClient.get(`events/${this.eventId}/registrations`).then(res => {
            let attendees = res.data.data.data;
            this.setState({attendees, pagination: res.data.data});
        }).catch(err => {
            toast.error("There was an error fetching attendees")
        })
    }

    componentDidMount(){
        this.fetchEvent();
        this.fetchAttendees();
    }

    render(){
        return(
            <div>
                <AdminLayout page_title="View Event">
                    <div className="row">
                        <div className="col-12 col-md-9">
                            <div className="table-responsive">
                                <p className="font-weight-bold">Registered Attendees ({this.state.pagination?.total ?? 0})</p>
                                {this.state.attendees.length > 0 ? <table className="table table-bordered table-striped">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>Username</th>
                                            <th>Firstname</th>
                                            <th>Lastname</th>
                                            <th>Phone</th>
                                            <th>Age</th>
                                            <th>Gender</th>
                                            <th>Location & Date</th>
                                            {/* <th>City</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.attendees.map((member, index) => {
                                            return (<tr key={index}>
                                                <td><Link to={`/admin/members/${member.user.id}/view`}>
                                                    {member.user.username}
                                                </Link></td>
                                                <td>{member.user.firstname}</td>
                                                <td>{member.user.lastname}</td>
                                                <td>{member.user.phoneNumber}</td>
                                                <td title={dayjs(member.user.dob).format("YYYY-MM-DD")}>{dayjs().diff(member.user.dob, 'years')} years old</td>
                                                <td>{member.user?.gender.charAt(0).toUpperCase() + member.user?.gender.slice(1)}</td>
                                                <td>{member.location.location} <br/> {dayjs(member.location.start_date).format("YYYY-MM-DD")}</td>
                                                {/* <td>{member.user.address.state}</td> */}
                                            </tr>)
                                        })}
                                    </tbody>
                                </table> : <div className="text-center">
                                    <p>There are no registered members yet</p>
                                </div>}
                            </div>
                        </div>
                        <div className="col-12 col-md-3">
                            <h5 className="font-weight-bold">Event</h5>
                            <p className="text-secondary">{this.state.event.title}</p>
                            <h5 className="font-weight-bold">Description</h5>
                            <p className="text-secondary" dangerouslySetInnerHTML={{ __html: this.state.event.description }}></p>
                            <h5 className="font-weight-bold">Locations</h5>
                            {(this.state.event.location != null && this.state.event.location.length > 0) ? <div>
                                {this.state.event.location.map(location => {
                                    return (
                                        <div key={location.id}>
                                            {location.state != null ? <p>State: {location.state}</p> : ''}
                                            <p>Venue: {location.location}</p>
                                            <p>Date: {dayjs(location.start_date).toString()}</p>
                                            <hr/>
                                        </div>
                                    )
                                })}
                                
                            </div> : <p>{this.state.event.location}</p>}
                            
                            <h5 className="font-weight-bold">Date</h5>
                            <p className="text-secondary">{dayjs(this.state.event.start_date).format("YYYY-MM-DD HH:mm")}</p>
                            <h5 className="font-weight-bold">End</h5>
                            <p className="text-secondary">{ dayjs(this.state.event.end_date).format("YYYY-MM-DD HH:mm")}</p>
                            
                            <h5 className="font-weight-bold">Open to</h5>
                            {(this.state.event.allowed_plans == null || this.state.event.allowed_plans.length < 1) ? <p className="text-secondary">All members</p> : <div>
                                <ul className="list">
                                    {this.state.event.allowed_plans.map((plan) => {
                                        return (
                                            <li key={plan.id}>{plan.plan_name} members</li>
                                        )
                                    })}
                                </ul>
                            </div> }
                            <p className="text-center">
                                <Link className="btn btn-link" to={`/admin/events/${this.eventId}/edit`}>Edit</Link>
                            </p>
                        </div>
                        
                    </div>
                </AdminLayout>
            </div>
        )
    }
}