import { Component } from "react";
import ReactLoader from "react-loader";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import httpClient from "../../../httpClient";
import AdminLayout from "../../layouts/admin";

export default class ViewMember extends Component{

    constructor(props){
        super(props)
        let { id } = this.props.match.params;
        this.state = {
            member: null,
            member_id: id,
            phone_number: "",            
            firstname: "",
            lastname: "",
            username: "",
            email: "",
            dob: "",
            gender: "",
            school: "",
            street: "",
            state: "",
            lga: "",
            states_list: require("../../../helpers/statesandlgas.json"),
            lgas_list: [],
            loading: false,
            password: "",
            confirm_password: "",
            activePlan: null,
            plans: []
        }    
    }
    
    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }
    handleStateInputChange = (event) => {
        
        const target = event.target;
        const value = target.value;
        this.setState({ state: value });
        
        const selectedState = this.state.states_list.find(item => {
            return item.state.name.toLowerCase() == value.toLowerCase();
        });

        if (selectedState != undefined) {
            this.setState({ lgas_list: selectedState.state.locals });
        } else {
            this.setState({ lgas_list: [] });
        }

    }
    getPlans = () => {
        httpClient.get('plans').then(res => {
            const plans = res.data.data.data;
            this.setState({ plans })
        }).catch(err => {
            console.log("There was an error fetching plans", err);
        })
    }

    componentDidMount(){
        this.fetchUser();
        this.getPlans()
    }
    fetchUser = () => {
        httpClient.get("/users/"+this.state.member_id).then(res => {
            let member = res.data.data;
            this.setState({member})
            this.setState({
                phone_number: member.phone_number,
                firstname: member.firstname,
                lastname: member.lastname,
                username: member.username,
                dob: new Date(member?.dob).toISOString().split('T')[0],
                gender: member.gender != null ? member.gender.toLowerCase() : "",
                school: member.school,
                email: member.email,
                street: member.street,
                state: member.state != null ? member.state.toLowerCase() : "",
                lga: member.lga != null ? member.lga.toLowerCase() : "",
            })
            if (member.plan_id != null){
                httpClient.get(`plans/${member.plan_id}`).then(res => {
                    let plan = res.data.data;
                    this.setState({activePlan: plan});
                })
            }
            if (member.state != null){
                // console.log("member state", member.state);
                const selectedState = this.state.states_list.find(item => {
                    console.log(item.state.name.toLowerCase(), "==", member.state.toLowerCase(), "equals", item.state.name.toLowerCase() == member.state.toLowerCase());
                    return item.state.name.toLowerCase() == member.state.toLowerCase();
                });
                // console.log(selectedState, "selected state");
                if (selectedState != undefined) {
                    this.setState({ lgas_list: selectedState.state.locals });
                } else {
                    this.setState({ lgas_list: [] });
                }
            }
        }).catch(err => {
            console.log(err)
            toast.error("There was a problem fetching member information");
        })
    }

    updateUser = (e) => {
        e.preventDefault();
        this.setState({ loading: true })
        let updateData = {
            phoneNumber: this.state.phone_number,
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            email: this.state.email,
            username: this.state.username,
            dob: this.state.dob,
            gender: this.state.gender,
            school: this.state.school,
            address: {
                street: this.state.street,
                state: this.state.state,
                lga: this.state.lga
            }
            
        }

        httpClient.put(`admin/members/${this.state.member_id}`, updateData).then(res => {
            toast.success("Member profile updated successfully");            
        }).catch(err => {
            toast.error("There was an error updating the member profile");
        }).finally(() => {
            this.setState({loading: false})
        })
    }

    changePassword = (e) => {
        e.preventDefault();
        
        if (this.state.password !== this.state.confirm_password){
            toast.error("The passwords do not match");
            return false;
        }

        this.setState({ loading: true })
        let data = {
            password: this.state.password
        }

        httpClient.put(`admin/members/${this.state.member_id}`, data).then(res => {
            toast.success("Member password updated successfully");
        }).catch(err => {
            toast.error("There was an error updating the member password");
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    render(){
        return(
            <div>
                <AdminLayout>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">Edit Profile</h4>
                                    </div>
                                    <div className="card-body">
                                        <form onSubmit={this.updateUser}>
                                            <div className="row">
                                                <div className="col-md-6 pr-1">
                                                    <div className="form-group">
                                                        <label>First Name</label>
                                                        <input type="text" className="form-control" placeholder="First name" name="firstname" value={this.state.firstname} onChange={this.handleInputChange} required/>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 pl-1">
                                                    <div className="form-group">
                                                        <label>Last Name</label>
                                                        <input type="text" className="form-control" placeholder="Last Name" name="lastname" value={this.state.lastname} onChange={this.handleInputChange} required/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                {/* <div className="col-md-5 pr-1">
                                                    <div className="form-group">
                                                        <label>Email</label>
                                                        <input type="email" className="form-control" placeholder="Email address" value={this.state.email} onChange={this.handleInputChange}/>
                                                    </div>
                                                </div> */}
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>Username</label>
                                                        <input type="text" className="form-control" name="username" placeholder="Username" value={this.state.username} onChange={this.handleInputChange} required/>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label htmlFor="phone_number">Phone Number</label>
                                                        <input type="tel" className="form-control" placeholder="080123456789" name="phone_number" value={this.state.phone_number} onChange={this.handleInputChange} required/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <div className="form-group">
                                                        <label htmlFor="Date of birth">Date of birth</label>
                                                        <input type="date" className="form-control" value={this.state.dob} onChange={this.handleInputChange} required/>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="form-group">
                                                        <label htmlFor="Gender">Gender</label>
                                                        <select className="form-control" name="gender" value={this.state.gender} onChange={this.handleInputChange} required>
                                                            <option value="">Gender</option>
                                                            <option value="female">Female</option>
                                                            <option value="male">Male</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Street Address</label>
                                                        <input type="text" name="street" className="form-control" placeholder="Home Address" value={this.state.street} onChange={this.handleInputChange}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-4 pr-1">
                                                    <div className="form-group">
                                                        <label>State</label>
                                                        <select className="form-control" value={this.state.state} name="state" onChange={this.handleStateInputChange}>
                                                            <option value="">Select state</option>
                                                            {this.state.states_list.map((item) => {
                                                                return <option value={item.state.name.toLowerCase()} key={item.state.id}>{item.state.name}</option>
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 px-1">
                                                    <div className="form-group">
                                                        <label>Lga</label>
                                                        <select className="form-control" name="lga" value={this.state.lga} onChange={this.handleInputChange}>
                                                            <option value="">Select lga</option>
                                                            {this.state.lgas_list.map((lga) => {
                                                                return <option key={lga.name} value={lga.name.toLowerCase()}>{lga.name}</option>
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>School name</label>
                                                        <input type="text" className="form-control" placeholder="Name of school" name="school" value={this.state.school} onChange={this.handleInputChange} required/>
                                                    </div>
                                                </div>
                                            </div>
                                            <ReactLoader loaded={!this.state.loading}>
                                                <button type="submit" className="btn btn-info btn-fill pull-right">Update Profile</button>
                                            </ReactLoader>
                                            <div className="clearfix"></div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card card-user">
                                    <div className="card-image">
                                        <img src="https://ununsplash.imgix.net/photo-1431578500526-4d9613015464?fit=crop&fm=jpg&h=300&q=75&w=400" alt="..."/>
                                    </div>
                                    <div className="card-body">
                                        <div className="author">
                                            <div>
                                                <img className="avatar border-gray" src={this.state.member?.avatar_url ?? require("../../../assets/img/default-member-avatar.png").default} alt="..."/>
                                                <h5 className="title">{(this.state.member?.firstname || "") + " " + this.state.member?.lastname || ""}</h5>
                                            </div>
                                            <p className="description">
                                                {this.state.member?.username || ""}
                                            </p>
                                        </div>
                                        <p className="description text-center">
                                            {this.state.activePlan != null ? <span className="badge badge-pill badge-success">{this.state.activePlan.plan_name}</span> : ''}
                                        </p>
                                    </div>
                                    <div className="card-footer">
                                        <form onSubmit={this.changePassword}>
                                            <div className="form-group">
                                                <label>Change password</label>
                                                <input className="form-control" placeholder="Enter new password" type="password" name="password" onChange={this.handleInputChange}/>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="Confirm password">Confirm password</label>
                                                <input type="password" name="confirm_password" id="confirm_password" placeholder="Enter password again" className="form-control" onChange={this.handleInputChange}/>
                                            </div>
                                            <ReactLoader loaded={!this.state.loading}>
                                                <div className="form-group">
                                                    <button className="btn btn-danger" type="submit">Change password</button>
                                                </div>
                                            </ReactLoader>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </AdminLayout>
            </div>
        )
    }
}