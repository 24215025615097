import { Component } from "react";
import AdminLayout from "../../layouts/admin";
import httpClient from "../../../httpClient";
import { toast } from "react-toastify";
import Auth from "../../../helpers/auth";
import { Redirect } from "react-router-dom";
import ReactLoader from "react-loader";
import { trim } from "jquery";
const states_and_lgas = require("../../../helpers/statesandlgas.json");
var dayjs = require('dayjs');
export default class CreateEvent extends Component{
    state = {
        name: "",
        description: "",
        location: "",
        membership_required: [],
        banner: null,
        start_date: "",
        end_date: "",
        location_template:{
            state: "",
            location: "",
            start_date: "",
            end_date: "",
        },
        locations: [],
        // event_time: "",
        validationErrors: [],
        plans: [],
        uploadedEvent: null,
        loading: false
    }
    componentDidMount(){
        this.fetchPlans();
        this.addNewLocationField();
    }
    addNewLocationField = () => {
        let locations = [...this.state.locations];
        locations.push({...this.state.location_template});
        this.setState({locations});
    }

    removeLocationField = (index) => {
        
        let locations = [...this.state.locations];
        locations.splice(index, 1);
        this.setState({locations});
    }
    fetchPlans = () => {
        httpClient.get('plans/active').then(res => {
            const plans = res.data.data;
            this.setState({ plans })
        }).catch(err => {
            console.log("There was an error fetching plans", err);
        })
    }

    handleLocationDateInputChange = (index, e) => {
        let allLocations = [...this.state.locations];
        let locationItem = allLocations[index];
        locationItem[e.target.name] = e.target.value;
        
        allLocations[index] = locationItem;
        this.setState({locations: [...allLocations]});
    }
    handleInputChange = (e) => {
        const target = e.target;
        let name = target.name;
        let value = null;
        
        if (target.type === 'checkbox'){
            
            const options = [...this.state.membership_required]
            let index;
            if (target.checked){
                options.push(target.value)
            }else{
                index = options.indexOf(target.value)
                options.splice(index, 1)
            }
            this.setState({membership_required: options});
            return;
            // value = target.value;
        }else if(target.type === 'file'){
            value = target.files[0];
        }else if(target.multiple === true){
            let options = Array.from(e.target.selectedOptions, option => option.value);
            value = options.filter(function (el) {
                return el != null && el != "" && el != undefined;
            }); 
        }
        else{
            value = target.value;
        }
        this.setState({
            [name]: value
        });
    }

    submitForm = (e) => {
        e.preventDefault();
        this.setState({loading: true, validationErrors: []});
        // let formData = new FormData();
        // formData.append('title', this.state.name);
        // formData.append('description', this.state.description);
        // formData.append('location', this.state.location);
        // formData.append('start_date', this.state.start_date);
        // formData.append('end_date', this.state.end_date);
        // formData.append('event_time', this.state.event_time);
        // formData.append('membership_types', this.state.membership_required);
        
        let data = {
            title: this.state.name,
            description: this.state.description,
            // location: this.state.location,
            locations: this.state.locations,
            start_date: this.state.start_date,
            end_date: this.state.end_date,
            membership_types: this.state.membership_required
        }
        httpClient.post("events", data).then(res => {
            this.setState({uploadedEvent: res.data.data});
            if (this.state.banner != null){
                this.uploadBanner();
            }else{
                toast.success("Event created successfully, members will now be able to register for the event");
                const {history} = this.props;
                history.push("/admin/events");
            }
            
        }).catch(err => {
            if (err.response && err.response.status == 400) {

                let formErrors = err.response.data.message;
                if (Array.isArray(formErrors) || (typeof formErrors === 'object' && formErrors !== null)) {
                    formErrors = Object.values(formErrors).flat();
                    this.setState({ validationErrors: formErrors })
                } else {
                    formErrors = formErrors.split(",");
                    formErrors = formErrors.map(item => trim(item));
                    this.setState({ validationErrors: formErrors })
                }
                toast.error("Please fill all required fields");
            } else {
                if (err.response && err.response.data.message != undefined) {
                    const errorMessage = [err.response.data.message];
                    this.setState({ validationErrors: errorMessage });
                }
            }
            toast.error("An error occured, please try again");
            this.setState({loading : false});
        })
    }

    uploadBanner = () => {
        let formData = new FormData();
        formData.append('banner', this.state.banner);
        httpClient.post(`events/upload-image/${this.state.uploadedEvent.id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data;',
            }
        }).then(res => {
            this.setState({loading: false});
            toast.success("Event created successfully, members will now be able to register for the event");
            const { history } = this.props;
            history.push("/admin/events");
        }).catch(err => {
            this.setState({ loading: false });
            toast.error("There was an error uploading the event banner")
        })
    }

    render = () => {
        return (
            <div>
                <AdminLayout page_title="Create a new event">
                    <div className="">
                        <div className="row">
                            <div className="col-md-2"></div>
                            <div className="col col-md-8">
                                <form onSubmit={this.submitForm}>
                                    {this.state.validationErrors.length > 0 &&
                                        <ul className="list-group">
                                            {this.state.validationErrors.map((error) => {
                                                return (<li className="list-group-item list-group-item-danger" key={error}>{error.charAt(0).toUpperCase() + error.slice(1)}</li>)
                                            })}
                                        </ul>
                                    }
                                    <div className="form-group">
                                        <label htmlFor="Event Name">Event name</label>
                                        <input type="text" className="form-control" placeholder="Name of event" name="name" onChange={this.handleInputChange}/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="Event description">Event description</label>
                                        <textarea className="form-control" name="description" placeholder="Description of event" onChange={this.handleInputChange}></textarea>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="Date">Date</label>
                                        <input className="form-control" type="datetime-local" min={dayjs(new Date().toDateString()).format("YYYY-MM-DDTHH:mm")} name="start_date" onChange={this.handleInputChange}/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="End date">End date</label>
                                        <input type="datetime-local" name="end_date" min={dayjs(this.state.start_date).format("YYYY-MM-DDTHH:mm")} id="end_date" className="form-control" onChange={this.handleInputChange}/>
                                    </div>
                                    <h4>Add dates and locations of the event</h4>
                                    {this.state.locations.map((template, index) =>{
                                        return (
                                            <div key={index}>
                                                <div className="border rounded p-3">
                                                    <div className="mb-3 float-right">
                                                        <button className="btn btn-success btn-sm" type="button" onClick={this.addNewLocationField}>Add</button>
                                                        {this.state.locations.length > 1 && <button className="btn btn-danger btn-sm" type="button" onClick={() => this.removeLocationField(index)}>Remove</button>}
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="event state">State</label>
                                                        <select className="form-control" value={this.state.state} name="state" onChange={(e) => this.handleLocationDateInputChange(index, e)}>
                                                            <option value="">Select state</option>
                                                            {states_and_lgas.map((item) => {
                                                                return <option value={item.state.name} key={item.state.id}>{item.state.name}</option>
                                                            })}
                                                        </select>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="location">Location</label>
                                                        <input className="form-control" name="location" type="text" placeholder="Phyiscal address or web url" onChange={(e) => this.handleLocationDateInputChange(index, e)} required/>
                                                    </div>
                                                    <div className="form-row">
                                                        <div className="col col-md-6 form-group">
                                                            <label htmlFor="Start date">Start date</label>
                                                            <input className="form-control" type="datetime-local" min={dayjs(this.state.start_date).format("YYYY-MM-DDTHH:mm")} name="start_date" onChange={(e) => this.handleLocationDateInputChange(index, e)} required/>
                                                        </div>
                                                        <div className="col col-md-6 form-group">
                                                            <label htmlFor="End date">End date</label>
                                                            <input type="datetime-local" name="end_date" min={dayjs(this.state.locations[index].start_date).format("YYYY-MM-DDTHH:mm")} max={dayjs(this.state.end_date).format("YYYY-MM-DDTHH:mm")} id="end_date" className="form-control" onChange={(e) => this.handleLocationDateInputChange(index, e)} required/>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        )
                                    })}
                                    
                                    {/* <div className="form-group">
                                        <label htmlFor="Membership required">Select which members can attend this event <small>(Leave blank to allow all member types)</small></label>
                                        {this.state.plans.map((plan) => {
                                            return(
                                                <div key={plan.id}>
                                                    <label htmlFor={plan.id}>
                                                        <input type="checkbox" name="membership_required" value={plan.id} id={plan.id} onChange={this.handleInputChange} />
                                                        <span> {plan.plan_name}</span>
                                                    </label>
                                                </div>
                                            )
                                            
                                        })}
                                        
                                    </div> */}
                                    <div className="form-group">
                                        <label htmlFor="Banner">Upload event banner</label>
                                        <input type="file" className="form-control" name="banner" accept="image/*" onChange={this.handleInputChange}></input>
                                    </div>
                                    <div>
                                        <ReactLoader loaded={!this.state.loading}>
                                            <button className="btn btn-primary" type="submit">Create event</button>
                                        </ReactLoader>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </AdminLayout>
            </div>
        )
    }
}