import React from "react";
import ReactDOM from "react-dom";
import AdminLayout from "./../layouts/admin";
import "../../assets/css/css-import-util.css";
import httpClient from "../../httpClient";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";
import Auth from "../../helpers/auth";
import { internationalizePhoneNumber } from "../../utils";
var Loader = require('react-loader');
class Login extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            identifier: "",
            password: "",
            validationErrors: [],
            loading: false,
            userType: this.props.location.pathname == "/admin/login" ? 'admin' : 'member'
        }
        this.doLogin = this.doLogin.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount(){
        
    }
    render(){
        if (Auth.isLoggedIn()) {
            return <Redirect to="/dashboard" />
        }
        require("../../assets/css/loginstyle.css")
        return (
            <div className="container-fluid">
                <div className="bodyWrapper">
                    <div className="row">
                        <div className="col col-sm-6 text-center">
                            <Link to="/">
                                <img src={process.env.PUBLIC_URL + '/logo.png'} className="bodyLogo" />
                            </Link>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col d-none d-md-block">
                            <img src={require("../../assets/img/memberauth/loginsideimage.png").default} className="signupSideImage" />
                        </div>
                        <div className="col">
                            <div className="">
                                <form className="indomitableForm validate-form" onSubmit={this.doLogin}>
                                    <h2 className="signupHeroHeader">
                                        Welcome back!
                                    </h2>
                                    <p className="signupHeroSubHeader">Login to your account</p>
                                    {this.state.validationErrors.length > 0 &&
                                        <ul className="list-group">
                                            {this.state.validationErrors.map((error) => {
                                                return (<li className="list-group-item list-group-item-danger" key={error.toString()}>{error}</li>)
                                            })}
                                        </ul>
                                    }
                                    <div className="wrap-input100 validate-input" data-validate="Valid email is required: ex@abc.xyz">
                                        <input className="input100" type="text" name="identifier" placeholder="Username" value={this.state.identifier} onChange={this.handleInputChange} required />
                                        <span className="focus-input100-1"></span>
                                        <span className="focus-input100-2"></span>
                                    </div>
                                    <div className="wrap-input100 rs1 validate-input" data-validate="Password is required">
                                        <input className="input100" type="password" name="password" placeholder="Password" value={this.state.password} onChange={this.handleInputChange} required />
                                        <span className="focus-input100-1"></span>
                                        <span className="focus-input100-2"></span>
                                    </div>
                                    <Loader loaded={!this.state.loading}>
                                        <div className="mt-4">

                                            <button className="btn-block" type="submit">
                                                Sign in
                                            </button>

                                        </div>
                                    </Loader>
                                    <div className="alternativeActionText mt-3" style={{ textAlign:"center" }}>
                                        Don't have an account? <Link to="/signup" className="txt2 hov1">
                                            Create now
                                        </Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="text-center">
                    <Link to="/pages/terms">
                        Terms & Conditions
                    </Link>
                    &nbsp;&nbsp;&nbsp;
                    <Link to="/pages/privacy-policy">
                        Privacy policy
                    </Link>
                </div>
                
            </div>
        );
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    doLogin(e){
        this.setState({validationErrors: [], loading: true})
        e.preventDefault();
        
        const loginData = {
            // phoneNumber: internationalizePhoneNumber(this.state.identifier),
            username: this.state.identifier,
            password: this.state.password
        }
        const authUrl = this.state.userType == 'admin' ? "auth/admin/login" : "auth/login"
        httpClient.post(authUrl, loginData).then(res => {
            const resData = res.data.data;
            const token = resData.token;
            const user = resData.user;
            Auth.saveToken(token);
            Auth.saveUser(user);
            const { history } = this.props;
            window.location.href="/dashboard";
        }).catch(err => {
            
            if (err.response && err.response.status == 400){

                let formErrors = err.response.data.message;
                if (Array.isArray(formErrors) || (typeof formErrors === 'object' && formErrors !== null)){
                    formErrors = Object.values(formErrors).flat();
                    this.setState({validationErrors: formErrors})
                }else{
                    formErrors = formErrors.split(",");
                    this.setState({ validationErrors: formErrors })
                }
            }else{
                if(err.response && err.response.data.message != undefined){
                    const errorMessage = [err.response.data.message];
                    this.setState({ validationErrors: errorMessage});
                }
            }
        }).finally(response => {
            this.setState({loading: false});
        })
    }
}

export default Login;

