import dayjs from "dayjs";
import { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import Auth from "../../../helpers/auth";
import httpClient from "../../../httpClient";
import AdminLayout from "../../layouts/admin";
import Pagination from "react-pagination-js";
import "react-pagination-js/dist/styles.css";

export default class ListEvents extends Component{
    constructor(props){
        super(props);
    }

    state = {
        events: [],
        pagination: null,
        error: null
    }

    componentDidMount(){
        this.fetchEvents();
    }

    fetchEvents = async (currentPage) => {
        try {
            this.setState({ currentPage });
            let getData = {
                page: currentPage
            }
            let response = await httpClient.get('events', {params: getData});    
            let events = response.data.data.data;
            this.setState({events});
            this.setState({ pagination: response.data.data })
            
        } catch (error) {
            if (error.response != undefined && error.response.data.message != null){
                this.setState({error: error.response.data.message})
            }else{
                this.setState({error: "There was an error fetching events"});
            }
        }   
    }

    deleteEvent = (index, e) => {
        e.preventDefault();
        if (!window.confirm("Are you sure you want to delete this event? This action is irreversible")) {
            return;
        }
        let events = this.state.events;

        let event = this.state.events[index];
        httpClient.delete(`events/${event.id}`).then(res => {
            toast.info("Event deleted successfully");
            events.splice(index, 1);
            this.setState({ events })
        }).catch(err => {
            toast.error("There was an error deleting that event, please try again");
        })
    }
    render(){
        if (this.state.events.length < 1){
            return (
                <AdminLayout page_title={`Events (${this.state.pagination?.total ?? 0})`}>
                    <div>
                        <Link to="/admin/events/create" className="btn btn-primary float-right">Add event</Link>
                    </div>
                    <div className="text-center">
                        No events have been created yet. <br/>
                        <Link to="/admin/events/create">Create one</Link>
                    </div>
                </AdminLayout>
            )
        }
        if (this.state.error != null){
            return (
                <AdminLayout page_title={`Events (${this.state.pagination?.total ?? 0})`}>
                    <div>
                        <Link to="/admin/events/create" className="btn btn-primary float-right">Add event</Link>
                    </div>
                    <div className="text-center">
                        {this.state.error}<br />
                        <button className="btn btn-warning" onClick={() => {window.location.reload(true)}}>Reload page</button>
                    </div>
                </AdminLayout>
            )
        }
        return (
            <div>
                <AdminLayout page_title={`Events (${this.state.pagination?.total ?? 0})`}>
                    <div>
                        <Link to="/admin/events/create" className="btn btn-primary float-right">Add new</Link>
                    </div>
                    
                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead className="thead-dark">
                                <tr>
                                    <th scope="col">Title</th>
                                    {/* <th scope="col">Location</th> */}
                                    <th scope="col">Date</th>
                                    <th scope="col">End</th>
                                    <th scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.events.map((event, index) => {
                                    return (
                                        <tr key={event.id}>
                                            <td scope="row">{event.title}</td>
                                            {/* <td>{event.location}</td> */}
                                            <td>{dayjs(event.start_date).format("YYYY-MM-DD HH:mm")}</td>
                                            <td>{dayjs(event.end_date).format("YYYY-MM-DD HH:mm")}</td>
                                            <td>
                                                <div className="dropdown">
                                                    <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <i className="fa fa-cog"></i> Actions
                                                </button>
                                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                        <Link className="dropdown-item" to={`/admin/events/${event.id}/view`}><i className="fa fa-eye"></i> View</Link>
                                                        <Link className="dropdown-item" to={`/admin/events/${event.id}/edit`}><i className="fa fa-edit"></i> Edit</Link>
                                                        <a className="dropdown-item" href="#" onClick={(e) => this.deleteEvent(index, e)}><i className="fa fa-times"></i> Delete</a>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                                
                            </tbody>
                        </table>
                        <Pagination 
                            currentPage={this.state.currentPage}
                            totalSize={this.state.pagination?.total ?? 0}
                            changeCurrentPage={this.fetchEvents}
                            sizePerPage={this.state.pagination?.limit}
                            numberOfPagesNextToActivePage={3}
                            theme="bootstrap"></Pagination>
                    </div>
                </AdminLayout>
            </div>
        )
    }
}